import React from 'react';
import { connect } from 'react-redux';

import { isPending } from '^/consts/responseStates';
import { loadItem } from '^/actions/items';
import { ACTIVITIES } from '^/consts/collectionKeys';
import { ACTIVITY_TYPE } from '^/models/activities';
import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';
import DemoModeMayDisallow from '^/components/app/perms/DemoModeMayDisallow';

import AdminStep from './AdminStep';
import Assessment from './Assessment';
import Audit from './Audit';
import ComplianceReview from './ComplianceReview';
import GenericActivity from './GenericActivity';
import PracticeMeeting from './PracticeMeeting';


export class ActivityPage extends PureComponent {

  componentWillMount() {
    this.props.loadItem(ACTIVITIES, this.props.activityId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activityId !== this.props.activityId) {
      this.props.loadItem(ACTIVITIES, nextProps.activityId);
    }
  }

  getActivityComponent(activity) {
    if (!activity) {
      return null;
    }

    switch (activity.get('type')) {
      case ACTIVITY_TYPE.ADMIN_STEP:
        return AdminStep;
      case ACTIVITY_TYPE.ASSESSMENT:
        return Assessment;
      case ACTIVITY_TYPE.AUDIT:
        return Audit;
      case ACTIVITY_TYPE.COMPLIANCE_REVIEW:
        return ComplianceReview;
      case ACTIVITY_TYPE.PRACTICE_MEETING:
        return PracticeMeeting;
      case ACTIVITY_TYPE.ACTIVITY:
      case ACTIVITY_TYPE.INSPECTION_MEETING:
      case ACTIVITY_TYPE.MANAGEMENT_MEETING:
      case ACTIVITY_TYPE.TRAINING_MEETING:
      default:
        return GenericActivity;
    }
  }

  render() {
    const { activity, response } = this.props;

    if (isPending(response)) {
      return <Loading />;
    }

    const ActivityComponent = this.getActivityComponent(activity);

    return (
      <DemoModeMayDisallow message="cannot view closed activities" response={response} goBack >
        {ActivityComponent && <ActivityComponent activity={activity} />}
      </DemoModeMayDisallow>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    activityId: props.params.uuid,
    activity: state.items.get(ACTIVITIES),
    response: state.responses.getIn(['loadItem', ACTIVITIES]),
  };
}

export default connect(mapStateToProps, { loadItem }) (ActivityPage);
