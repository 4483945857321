import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select'
import ImmutablePropTypes from 'react-immutable-proptypes';

import PureComponent from '^/components/common/PureComponent';

import { getTemplateActivitiesForCurrentGroup } from '^/actions/actions';
import { selectTemplateActivitiesOptions, selectedTemplateActivityLabel } from '^/components/app/groups/insights/utils';
import withFilters from '^/components/app/groups/insights/hoc/withFilters';


export class ActivityTemplateFilter extends PureComponent {

  componentWillMount() {
    this.props.getTemplateActivitiesForCurrentGroup();
  }

  onChange(e) {
    const filterValue = e ? e.value : undefined
    this.props.onChange(filterValue);
  }

  render() {
    const { templateActivitiesOptions, selectedValue } = this.props;

    return (
      <div>
        <span className="caption mt-1">view by activity:</span>
        <Select
          className="print-hide"
          options={templateActivitiesOptions}
          onChange={(e) => this.onChange(e)}
          value={selectedValue}
          isClearable
        />
        <span className="print-dropdown-as-text caption mt-1">
          {selectedTemplateActivityLabel(templateActivitiesOptions, selectedValue)}
        </span>
      </div>
    );
    }
}

ActivityTemplateFilter.propTypes = {
  title: React.PropTypes.string.isRequired,
  value: React.PropTypes.string.isRequired,
  selectedValue: React.PropTypes.string.isRequired,
  filterKey: React.PropTypes.string.isRequired,
  insightsConfig: ImmutablePropTypes.list.isRequired
};


export function mapStateToProps(state) {

  return {
    templateActivitiesOptions: selectTemplateActivitiesOptions(state.templateActivitiesForGroup)
  }
}


export default connect(mapStateToProps, {getTemplateActivitiesForCurrentGroup})(withFilters(ActivityTemplateFilter));
