import { routeActions } from 'react-router-redux';
import { Map, List } from 'immutable';
import ActionChain from '^/actions/ActionChain';
import * as actions from '^/actions/actions';
import * as collections from '^/actions/collections';
import {
  ACTIVITIES,
  USERS,
  PRACTICES,
  MY_ACTIVITIES,
  SEARCHED_ACTIVITIES,
  SEARCHED_TASKS,
  DOCUMENTS,
  SEARCHED_DOCS,
  SEARCHED_UPLOADED_ACTIVITY_TASK_FILES,
  MY_TASKS_COMPLETE,
  MY_TASKS,
  ADMIN_TEMPLATE_DOCUMENT_WORKING_VERSIONS,
  PRIVATE_TEMPLATES_COPIES,
  STAFF_PRACTICE,
  ACTIVITY_RELATED_TEMPLATE,
  ADMIN_TEMPLATE_DOCUMENT,
  ADMIN_TEMPLATE_DOCUMENT_VERSION,
  GROUP_DETAILS,
  TASKS,
  UPLOADED_ACTIVITY_TASK_FILES,
  DASHBOARD_DOCUMENTS_READ_REQUESTS,
  DOCUMENT_VERSIONS_TO_READ,
  DIGITAL_RECORDS_CREATE,
  DIGITAL_RECORDS_LIST,
  CHECKLIST_RECORD_GROUP,
  CYCLE_RESPONSIBILITIES,
  RESPONSIBILITIES,
  GROUP_TEMPLATE_DOCUMENTS,
  TEMPLATE_FOLDERS,
} from '^/consts/collectionKeys';
import * as items from '^/actions/items';

import {
  closeModal,
  openExportTableModal,
  openEditMemberModal,
  openSuspendPracticeMemberModal,
  openRetirePracticeMemberModal,
  openReinstatePracticeMemberModal,
  openPracticeReportModal,
  openReportTableModal,
  openCycleReportModal,
  openExitConfirmationModal,
  openComparisonEditorExitConfirmationModal,
  openSetupCycleDateFormModal,
} from '^/actions/modals';
import * as adminActivity from '^/components/admin/activities/activity';
import * as adminHealthcheck from '^/components/admin/healthcheck/healthcheck';
import {
  PAGE_SIZE,
  LIST_NAME_PREFIX,
} from '^/components/admin/content/list/AdminList';
import * as adminTemplateDoc from '^/components/admin/template-documents/template-documents';
import * as StaffMemberFilter from '^/components/app/dashboard/StaffMemberFilter';
import { hasPracticeGroup } from '^/models/practice';
import { formatBackendDate } from '^/utils';

import makeActionChain from './makeActionChain';
import { PRIORITIES } from '^/models/constants';

const basicGetAndCheck = new ActionChain(actions.getUserInfo).then(
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  () => {},
  // Not logged in!
  apiError => actions.logout(apiError && apiError.response)
);

export function getAndCheckUserInfo() {
  return basicGetAndCheck.process();
}

export function checkUserInfoAndLogin() {
  return basicGetAndCheck
    .then(() => routeActions.push('/page/dashboard/'))
    .process();
}

export function updatePracticeAndCloseModal(id, practice) {
  return makeActionChain([
    () => items.updateItem(PRACTICES, id, practice),
    closeModal,
  ]);
}

export function closeModalAndRedirectToDashboard() {
  return makeActionChain([
    () => closeModal(),
    () => routeActions.push('/page/dashboard/'),
  ]);
}

export function addUserAndCloseModal(userData) {
  return makeActionChain([
    () => collections.addItem(USERS, userData),
    () => closeModal(),
  ]);
}

export function createItemAndCloseModal(type, data) {
  return makeActionChain([
    () => items.createItem(type, data),
    () => closeModal(),
  ]);
}

export function createDigitalRecordTaskAndCloseModal(data) {
  return makeActionChain([
    () => actions.createDigitalRecordTask(data),
    () => closeModal(),
  ]);
}

export function createGroupTaskAndCloseModal(type, data) {
  return makeActionChain([
    () => items.createItem(type, data),
    payload =>
      actions.pollUpdateGroupTask(payload.group_task_update_job_id, null),
  ]);
}

export function updateGroupTaskAndCloseModal(type, groupTaskId, data) {
  return makeActionChain([
    () => items.updateItem(type, groupTaskId, data),
    payload =>
      actions.pollUpdateGroupTask(
        payload.group_task_update_job_id,
        groupTaskId
      ),
  ]);
}

export function archiveGroupTask(type, groupTaskId, data) {
  return makeActionChain([
    () => items.patchItem(type, groupTaskId, data),
    payload =>
      actions.pollUpdateGroupTask(
        payload.group_task_update_job_id,
        groupTaskId
      ),
  ]);
}

export function bulkDocSendReadRequestAndCloseModal(type, data) {
  return makeActionChain([
    () => items.createItem(type, data),
    payload => actions.pollBulkDocSendReadRequest(payload.job_tracker_id),
  ]);
}

export function patchItemSetUpdatingForm(type, id, data, source = type) {
  return makeActionChain([
    () => actions.setPendingFields(type, data),
    () => items.patchItem(type, id, data, source, true),
  ]);
}

export function patchItemAndClosePopup(type, id, data, popup) {
  return makeActionChain([
    () => items.patchItem(type, id, data),
    () => actions.collapseUiComponent(popup),
  ]);
}

export function changePracticeAndNavToDashboard(practiceId) {
  return makeActionChain([
    () => actions.switchPractice(practiceId),
    () => routeActions.push('/page/dashboard/'),
  ]);
}

export function changePracticeAndNavToRoute(practiceId, routeUrl) {
  return makeActionChain([
    () => actions.switchPractice(practiceId),
    () => routeActions.push(routeUrl),
  ]);
}

export function changePracticeAndOpenSetupCycleDateFormModal(practice) {
  return makeActionChain([
    () => actions.switchPractice(practice.get('id')),
    () => openSetupCycleDateFormModal(practice),
  ]);
}

export function updateUserFormAndNavToUserProfilePage(userId, data) {
  return makeActionChain([
    () => items.updateItem(USERS, userId, data),
    () => routeActions.push('/page/profile/' + userId + '/'),
  ]);
}

export function setCurrentMonthAndGetMyActivities(
  currentMonth,
  currentPracticeId,
  excludeComplianceReviews,
  calendarFilters = Map()
) {
  const priorityFilters = calendarFilters.get('priority', List());
  let updatedCalendarFilters = calendarFilters.set(
    'priority',
    priorityFilters.filter(priority => priority !== PRIORITIES.CRITICAL)
  );

  if (updatedCalendarFilters.get('priority').size === 0) {
    updatedCalendarFilters = updatedCalendarFilters.delete('priority');
  }

  const filters = {
    ...updatedCalendarFilters.toJS(),
    ends_after: formatBackendDate(currentMonth.clone().startOf('month')),
    starts_before: formatBackendDate(
      currentMonth
        .clone()
        .add(1, 'month')
        .startOf('month')
    ),
    cycle__practice__id: currentPracticeId,
    exclude_compliance_reviews: excludeComplianceReviews,
  };
  return makeActionChain([
    () => actions.setCurrentMonth(currentMonth),
    () => collections.getMyActivities({ filters }),
  ]);
}

export function navigateToCalendarMonth(month) {
  return makeActionChain([
    () => actions.setInitialMonth(month),
    () => routeActions.push('/page/calendar/'),
  ]);
}

export function searchDocumentsActivitiesAndTasks(query, practice) {
  return makeActionChain([
    () =>
      collections.getCollection(
        MY_ACTIVITIES,
        {
          filters: {
            cycle__id: practice.getIn(['current_cycle', 'id']),
            search: query,
          },
          pageSize: 6,
        },
        SEARCHED_ACTIVITIES
      ),
    () =>
      collections.getCollection(SEARCHED_TASKS, {
        filters: { practice: practice.get('id'), search: query },
        pageSize: 6,
      }),
    () =>
      collections.getCollection(
        DOCUMENTS,
        { filters: { search: query } },
        SEARCHED_DOCS
      ),
    () =>
      collections.getCollection(
        UPLOADED_ACTIVITY_TASK_FILES,
        { filters: { search: query } },
        SEARCHED_UPLOADED_ACTIVITY_TASK_FILES
      ),
  ]);
}

export function clearSearchResults() {
  return makeActionChain([
    () => collections.clearCollection(SEARCHED_ACTIVITIES),
    () => collections.clearCollection(SEARCHED_TASKS),
    () => collections.clearCollection(SEARCHED_DOCS),
  ]);
}

export function setUserLockAndReloadUser(userId, locked, itemName) {
  return makeActionChain([
    () => actions.setUserLock(userId, locked),
    () => items.loadItem(itemName, userId),
  ]);
}

export function exportTable(table) {
  return makeActionChain([
    () => actions.getExportUrl(table),
    p => openExportTableModal(table, p),
  ]);
}

export function createItemAndRunCallback(
  model,
  data,
  collectionName,
  formName,
  callback
) {
  return makeActionChain([
    () => items.createItem(model, data, collectionName),
    newItem => callback(model, data, collectionName, formName, newItem),
  ]);
}

export function createItemRunCallbackAndCloseModal(
  model,
  data,
  collectionName,
  formName,
  callback
) {
  return makeActionChain([
    () => items.createItem(model, data, collectionName),
    newItem => callback(model, data, collectionName, formName, newItem),
    () => closeModal(),
  ]);
}

export function patchItemAndRunCallback(
  model,
  itemId,
  data,
  collectionName,
  callback
) {
  return makeActionChain([
    () => items.patchItem(model, itemId, data, collectionName),
    newItem => callback(model, itemId, data, collectionName, newItem),
  ]);
}

export function getDraftsCollectionAndNavTo(path) {
  return makeActionChain([
    () =>
      collections.getCollection(
        adminTemplateDoc.ADMIN_TEMPLATE_DOCUMENT_DRAFT_MODEL,
        {
          page: 1,
          pageSize: PAGE_SIZE,
        },
        LIST_NAME_PREFIX + adminTemplateDoc.ADMIN_TEMPLATE_DOCUMENT_DRAFT_MODEL
      ),
    () => routeActions.push(path),
  ]);
}

export function setupPersonalDetailsAndReloadUser(userId, data) {
  return makeActionChain([
    () => actions.setupPersonalDetails(userId, data),
    () => actions.getUserInfo(),
  ]);
}

export function setupPracticeDetailsAndReloadUser(data) {
  return makeActionChain([
    () => actions.setupPracticeDetails(data),
    () => actions.getUserInfo(),
    () => collections.getAllCollection(PRACTICES),
  ]);
}

export function setupAddTeamMember(practiceId, data) {
  return makeActionChain([
    () => items.createItem(USERS, data),
    () => actions.getSetupTeamMembers(practiceId),
  ]);
}

export function setupAddExistingTeamMembers(practiceId, members) {
  return makeActionChain([
    () => items.patchItem(PRACTICES, practiceId, { members }),
    () => actions.getSetupTeamMembers(practiceId),
  ]);
}

export function setupTeamMembersAndReloadUser(practiceId, data) {
  return makeActionChain([
    () => actions.setupTeamMembers(practiceId, data),
    () => actions.getUserInfo(),
  ]);
}

export function setupCycleResponsibilitiesAndReloadUser(practiceId, data) {
  return makeActionChain([
    () => actions.setupCycleResponsibilities(practiceId, data),
    () => actions.getUserInfo(),
  ]);
}

export function setupCycleRemindersAndReloadUser(cycleId, data) {
  return makeActionChain([
    () => actions.setupCycleReminders(cycleId, data),
    () => actions.getUserInfo(),
  ]);
}

export function setupCycleGroupTasksAndReloadUser(cycleId, data) {
  return makeActionChain([
    () => actions.setupCycleGroupTasks(cycleId, data),
    () => actions.getUserInfo(),
  ]);
}

export function setupComplianceReviewsAndReloadUser(cycleId, data) {
  return makeActionChain([
    () => actions.setupComplianceReviews(cycleId, data),
    () => actions.getUserInfo(),
  ]);
}

export function setupSecurityAuditsAndReloadUser(cycleId, data) {
  return makeActionChain([
    () => actions.setupSecurityAudits(cycleId, data),
    () => actions.getUserInfo(),
  ]);
}

export function setupAssessmentsAndReloadUser(cycleId, data) {
  return makeActionChain([
    () => actions.setupAssessments(cycleId, data),
    () => actions.getUserInfo(),
  ]);
}

export function setupMeetingsAndRedirectToSetupComplete(cycleId, data) {
  return makeActionChain([
    () => actions.setupMeetings(cycleId, data),
    () => routeActions.push(`/page/setup-complete/${cycleId}/`),
  ]);
}

export function setPendingUploadAndResetError(form, field, file, responseName) {
  return makeActionChain([
    () => actions.setPendingUploadInForm(form, field, file),
    () => actions.resetResponseErrorForKey(responseName, field),
  ]);
}

export function closeMenuAndFollowLink(collapseId, link) {
  return makeActionChain([
    () => actions.setCollapse(collapseId, true),
    () => routeActions.push(link),
  ]);
}

export function collapseUiComponentAndFollowLink(collapseId, link) {
  return makeActionChain([
    () => actions.collapseUiComponent(collapseId),
    () => routeActions.push(link),
  ]);
}

export function updatePracticeMember(practiceId, memberId, data) {
  return makeActionChain([
    () => items.patchItem(STAFF_PRACTICE, memberId, data),
    () => collections.getAllPracticeMembers(practiceId),
    () => closeModal(),
  ]);
}

export function resetResponseAndOpenPracticeMemberModal(member, practiceId) {
  return makeActionChain([
    () => actions.resetResponse('updateItem'),
    () => openEditMemberModal(member, practiceId),
  ]);
}

export function setTeamMemberAndNavToDashboard(user) {
  return makeActionChain([
    () => actions.setCurrentTeamMember(user),
    () => actions.setCollapse(StaffMemberFilter.COLLAPSE_ID, false),
    () => routeActions.push('/page/dashboard/'),
  ]);
}

export function changeStepAndAddReassignData(tabSet, newStep, form, data) {
  return makeActionChain([
    () => actions.setActiveTab(tabSet, newStep),
    () => actions.addReassignData(form, data),
  ]);
}

export function patchTeamMembersAndReloadMembers(practiceId, members) {
  return makeActionChain([
    () => items.patchItem(PRACTICES, practiceId, { members }),
    () => collections.getAllPracticeMembers(practiceId),
  ]);
}

export function createNewTeamMemberAndReloadMembers(practiceId, data) {
  return makeActionChain([
    () => items.createItem(USERS, data),
    () => collections.getAllPracticeMembers(practiceId),
  ]);
}

export function onReassignSuccess(practiceId) {
  return makeActionChain([
    () => actions.clearReassignData(),
    () => closeModal(),
    () => collections.getAllPracticeMembers(practiceId),
  ]);
}

export function resetResponseAndOpenSuspendPracticeMemberModal(id, practiceId) {
  return makeActionChain([
    () => actions.resetResponse('updateItem'),
    () => openSuspendPracticeMemberModal(id, practiceId),
  ]);
}

export function resetResponseAndOpenRetirePracticeMemberModal(id, practiceId) {
  return makeActionChain([
    () => actions.resetResponse('updateItem'),
    () => openRetirePracticeMemberModal(id, practiceId),
  ]);
}

export function resetResponseAndOpenReinstatePracticeMemberModal(
  id,
  practiceId
) {
  return makeActionChain([
    () => actions.resetResponse('updateItem'),
    () => openReinstatePracticeMemberModal(id, practiceId),
  ]);
}

export function closeModalAndNavigateTo(path) {
  return makeActionChain([closeModal, () => routeActions.push(path)]);
}

export function reloadUserAndRedirectToSetupCycle(cycle) {
  return makeActionChain([
    () => actions.getUserInfo(),
    () => routeActions.push(`/page/setup-cycle/${cycle.id}/practice-details/`),
  ]);
}

export function setupNewCycleAndRedirectToSetup(practiceId, startDate) {
  return makeActionChain([
    () => actions.setupNewCycle(practiceId, startDate),
    cycle => reloadUserAndRedirectToSetupCycle(cycle),
  ]);
}

export function setupNewCycleRedirectToSetupAndSetCurrentPractice(
  practiceId,
  startDate
) {
  return makeActionChain([
    () => actions.switchPractice(practiceId),
    () => setupNewCycleAndRedirectToSetup(practiceId, startDate),
  ]);
}

export function setupCycleComplianceDetailsAndRedirectToOptionalAudits(
  cycleId,
  data
) {
  return makeActionChain([
    () => actions.setupCycleComplianceDetails(cycleId, data),
    () => actions.getUserInfo(),
    () => routeActions.push(`/page/setup-cycle/${cycleId}/optional-audits/`),
  ]);
}

export function setupCycleOptionalAuditsAndRedirectToReviewRiskAssessments(
  cycleId,
  data
) {
  return makeActionChain([
    () => actions.setupCycleOptionalAudits(cycleId, data),
    () => actions.getUserInfo(),
    () => routeActions.push(`/page/setup-cycle/${cycleId}/risk-assessments/`),
  ]);
}

export function setupCycleReviewRiskAssessmentsAndRedirectToReviewMeetings(
  cycleId,
  data
) {
  return makeActionChain([
    () => actions.setupCycleReviewRiskAssessments(cycleId, data),
    () => actions.getUserInfo(),
    () => routeActions.push(`/page/setup-cycle/${cycleId}/review-meetings/`),
  ]);
}

export function setupCycleReviewMeetingsAndRedirectToReviewTasks(
  cycleId,
  data
) {
  return makeActionChain([
    () => actions.setupCycleReviewMeetings(cycleId, data),
    () => actions.getUserInfo(),
    () => routeActions.push(`/page/setup-cycle/${cycleId}/review-tasks/`),
  ]);
}

export function setupCycleReviewTasksAndRedirectToNextStage(
  cycleId,
  practice,
  data
) {
  const nextStepUrl = hasPracticeGroup(practice)
    ? `/page/setup-cycle/${cycleId}/review-group-tasks/`
    : `/page/setup-cycle/${cycleId}/compliance-reviews/`;
  return makeActionChain([
    () => actions.setupCycleReviewTasks(cycleId, data),
    () => actions.getUserInfo(),
    () => routeActions.push(nextStepUrl),
  ]);
}

export function setupCycleReviewGroupTasksAndRedirectToComplianceReviews(
  cycleId,
  data
) {
  return makeActionChain([
    () => actions.setupCycleReviewGroupTasks(cycleId, data),
    () => actions.getUserInfo(),
    () => routeActions.push(`/page/setup-cycle/${cycleId}/compliance-reviews/`),
  ]);
}

export function createCycleSetupTaskAndCloseModal(cycleId, data) {
  return makeActionChain([
    () => actions.createCycleSetupTask(cycleId, data),
    () => closeModal(),
  ]);
}

export function setupCycleComplianceReviewsAndRedirectToDashboard(
  cycleId,
  data
) {
  return makeActionChain([
    () => actions.setupCycleComplianceReviews(cycleId, data),
    () => actions.getUserInfo(),
    () => routeActions.push('/page/dashboard/'),
  ]);
}

export function adoptTemplateVersionAndRedirectTo(
  templateId,
  content,
  practice,
  practiceGroup,
  makePath,
  activityId
) {
  return makeActionChain([
    () =>
      items.adoptTemplateVersion(
        templateId,
        content,
        practice,
        practiceGroup,
        activityId
      ),
    version => routeActions.push(makePath(version)),
  ]);
}

export function adoptTemplateVersionRenderDocumentAndOptionallyRedirect(
  templateId,
  content,
  practice,
  practiceGroup,
  makePath,
  activityId,
  documentType = null,
  shouldRedirect = true,
  andThen
) {
  let versionHolder = null;
  return makeActionChain([
    () =>
      items.adoptTemplateVersion(
        templateId,
        content,
        practice,
        practiceGroup,
        activityId
      ),
    version => {
      versionHolder = version; // HACK: you cant chain routeActions, so store the response to use later
      return (
        documentType && actions.renderDocument(documentType, versionHolder.id)
      );
    },
    () => shouldRedirect && routeActions.replace(makePath(versionHolder)),
    () => andThen && andThen(),
  ]);
}

export function copyTemplateVersionAndRedirectTo(
  templateId,
  content,
  practice,
  practiceGroup,
  makePath,
  activityId
) {
  return makeActionChain([
    () =>
      items.copyTemplateVersion(
        templateId,
        content,
        practice,
        practiceGroup,
        activityId
      ),
    version => routeActions.replace(makePath(version)),
  ]);
}

export function copyTemplateVersionWithNameAndRedirectToAndPrint(
  templateId,
  content,
  name,
  practice,
  practiceGroup,
  makePath,
  activityId,
  documentType = null
) {
  let versionHolder = null;
  return makeActionChain([
    () =>
      items.copyTemplateVersionWithName(
        templateId,
        content,
        name,
        practice,
        practiceGroup,
        activityId
      ),
    version => {
      versionHolder = version; // HACK: you cant chain routeActions, so store the response to use later
      return (
        documentType && actions.renderDocument(documentType, versionHolder.id)
      );
    },
    () =>
      actions.collapseUiComponent(
        `TEMPLATE_DOCUMENT_VERSION.content.${templateId}`
      ),
    () => closeModal(),
    () => routeActions.replace(makePath(versionHolder)),
  ]);
}

export function rebaseCopiedTemplateAndRedirectTo(
  templateId,
  content,
  practice,
  makePath,
  activityId
) {
  return makeActionChain([
    () => items.rebaseCopiedTemplate(templateId, content, practice, activityId),
    version => routeActions.replace(makePath(version)),
  ]);
}

export function discardWorkingDraftAndRedirectTo(templateId, path) {
  return makeActionChain([
    () => items.discardWorkingDraft(templateId),
    () => routeActions.replace(path),
  ]);
}

export function uploadActivityFileReloadActivityAndCloseModal(
  activityId,
  data
) {
  return makeActionChain([
    () => actions.uploadActivityTaskAssigneeIdFile(activityId, data),
    () => closeModal(),
    () => items.loadItem(ACTIVITIES, activityId),
  ]);
}

export function uploadTaskAssigneeFileReloadTaskFilesAndCloseModal(
  taskAssigneeId,
  taskId,
  data
) {
  return makeActionChain([
    () => actions.uploadActivityTaskAssigneeIdFile(taskAssigneeId, data),
    () => closeModal(),
    () => items.loadItem(MY_TASKS, taskId),
    () => collections.getAllCollection(MY_TASKS),
  ]);
}

export function removeUploadedFileReloadActivityAndCloseModal(
  activityId,
  uploadedFileId
) {
  return makeActionChain([
    () => actions.removeUploadedFile(activityId, uploadedFileId),
    () => closeModal(),
    () => items.loadItem(ACTIVITIES, activityId),
  ]);
}

export function removeUploadedFileReloadTaskAssigneeFilesAndCloseModal(
  taskAssigneeId,
  taskId,
  uploadedFileId
) {
  return makeActionChain([
    () => actions.removeUploadedFile(taskAssigneeId, uploadedFileId),
    () => closeModal(),
    () => items.loadItem(MY_TASKS, taskId),
    () => collections.getCollection(MY_TASKS_COMPLETE),
  ]);
}

export function togglePrivacyUploadedFileReloadActivityAndCloseModal(
  activityId,
  uploadedFileId,
  is_private
) {
  return makeActionChain([
    () =>
      actions.togglePrivacyUploadedFile(activityId, uploadedFileId, is_private),
    () => closeModal(),
    () => items.loadItem(ACTIVITIES, activityId),
  ]);
}

export function changeFileFolderAndCloseModal(
  activityTaskId,
  uploadedFileId,
  data
) {
  return makeActionChain([
    () => actions.changeFileFolder(activityTaskId, uploadedFileId, data),
    () => closeModal(),
  ]);
}

export function togglePrivacyUploadedFileReloadTaskAssigneeFilesAndCloseModal(
  taskAssigneeId,
  taskId,
  uploadedFileId,
  is_private
) {
  return makeActionChain([
    () =>
      actions.togglePrivacyUploadedFile(
        taskAssigneeId,
        uploadedFileId,
        is_private
      ),
    () => closeModal(),
    () => items.loadItem(MY_TASKS, taskId),
    () => collections.getCollection(MY_TASKS_COMPLETE),
  ]);
}

export function resetDemoAndLoadMe() {
  return makeActionChain([
    () => actions.resetDemo(),
    () => actions.getUserInfo(),
    () => collections.getCollection(MY_TASKS),
    () => collections.getCollection(PRACTICES),
  ]);
}

export function getUserInfoAndNavigateTo(path) {
  return makeActionChain([
    () => actions.getUserInfo(),
    () => routeActions.push(path),
  ]);
}

export function deleteWorkingVersionandReloadTemplateVersion(
  workingVersionId,
  templateVersionId
) {
  return makeActionChain([
    () =>
      collections.deleteItem(
        ADMIN_TEMPLATE_DOCUMENT_WORKING_VERSIONS,
        workingVersionId
      ),
    () => closeModal(),
    () => items.loadItem(ADMIN_TEMPLATE_DOCUMENT_VERSION, templateVersionId),
  ]);
}

export function saveAsWorkingDraftAndReloadTemplateVersion(
  data,
  sourceVersionId
) {
  return makeActionChain([
    () => actions.resetResponse('updateItem'),
    () => actions.resetResponse('createItem'),
    () =>
      items.createItem(
        ADMIN_TEMPLATE_DOCUMENT_WORKING_VERSIONS,
        Object.assign({}, data, { source_version: sourceVersionId })
      ),
    () => items.loadItem(ADMIN_TEMPLATE_DOCUMENT_VERSION, sourceVersionId),
  ]);
}

export function publishAsCurrentVersionAndReloadTemplateVersion(
  templateVersionId,
  data
) {
  return makeActionChain([
    () => actions.resetResponse('updateItem'),
    () => actions.resetResponse('createItem'),
    () =>
      items.patchItem(ADMIN_TEMPLATE_DOCUMENT_VERSION, templateVersionId, data),
    () => closeModal(),
    () => items.loadItem(ADMIN_TEMPLATE_DOCUMENT_VERSION, templateVersionId),
  ]);
}

export function publishAsNewVersionAndReloadTemplateVersion(
  templateDocumentId,
  data
) {
  return makeActionChain([
    () => actions.resetResponse('updateItem'),
    () => actions.resetResponse('createItem'),
    () =>
      items.createItem(
        ADMIN_TEMPLATE_DOCUMENT_VERSION,
        Object.assign({}, data, { template_document: templateDocumentId })
      ),
    () => closeModal(),
    () => items.loadItem(ADMIN_TEMPLATE_DOCUMENT, templateDocumentId),
  ]);
}

export function saveCycleResponsibilitiesAndRedirectTo(data, path) {
  return makeActionChain([
    () => actions.saveCycleResponsibilities(data),
    () => collections.getAllCollection(RESPONSIBILITIES),
    () => collections.getAllCollection(CYCLE_RESPONSIBILITIES),
    () => routeActions.push(path),
  ]);
}

export function upgradeDemoAndCloseModal() {
  return makeActionChain([actions.upgradeDemo, closeModal]);
}

export function generatePracticeReport() {
  return makeActionChain([
    () => actions.getPracticeReportUrl(),
    p => openPracticeReportModal(p),
  ]);
}

export function publishAndRenderQMRReport(model, itemId, data, collectionName) {
  return makeActionChain([
    () => items.patchItem(model, itemId, data, collectionName),
    () => actions.renderQMRReport(itemId),
  ]);
}

export function retryExportAndResetPollProgress(exportId) {
  return makeActionChain([
    () => actions.retryExport(exportId),
    () => actions.pollExport(exportId),
  ]);
}

export function markTaskAssigneeCompleteAndCloseModal(
  taskAssigneeId,
  taskId,
  data
) {
  return makeActionChain([
    () => actions.markTaskAssigneeComplete(taskAssigneeId, data),
    closeModal,
    () => items.loadItem(TASKS, taskId),
  ]);
}

export function patchItemThen(onComplete, model, itemId, data, collectionName) {
  return makeActionChain([
    () => items.patchItem(model, itemId, data, collectionName),
    onComplete,
  ]);
}

export function applyTemplateToLiveCyclesCloseModalAndCheckProgress(
  templateActivity,
  data
) {
  return makeActionChain([
    () => actions.applyTemplateActivityToLiveCycle(templateActivity, data),
    () =>
      actions.checkTemplateActivityLiveApplicationProgress(
        templateActivity.get('id')
      ),
    () => closeModal(),
  ]);
}

export function reloadActivityAndRules(activityId, templateActivityFilter) {
  return makeActionChain([
    () =>
      items.loadItem(adminActivity.ADMIN_TEMPLATE_ACTIVITIES_MODEL, activityId),
    () =>
      collections.getCollection(
        adminActivity.ADMIN_TEMPLATE_ACTIVITY_REGION_HIDE_RULES_MODEL,
        { filters: templateActivityFilter },
        adminActivity.TEMPLATE_ACTIVITY_REGION_HIDE_RULE
      ),
    () =>
      collections.getCollection(
        adminActivity.ADMIN_TEMPLATE_ACTIVITY_HIDE_RULES_MODEL,
        { filters: templateActivityFilter },
        adminActivity.TEMPLATE_ACTIVITY_HIDE_RULE
      ),
    () =>
      collections.getCollection(
        adminActivity.ADMIN_TEMPLATE_ACTIVITIES_CYCLE_CONFIGURATION_SHOW_RULE_MODEL,
        { filters: templateActivityFilter },
        adminActivity.TEMPLATE_ACTIVITY_CYCLE_CONFIGURATION_SHOW_RULE
      ),
  ]);
}

export function loadHealthcheckQuestionAndRules(
  healthcheckQuestionId,
  healthcheckQuestionFilter
) {
  return makeActionChain([
    () =>
      items.loadItem(
        adminHealthcheck.ADMIN_HEALTHCHECK_QUESTION_MODEL,
        healthcheckQuestionId
      ),
    () =>
      collections.getCollection(
        adminHealthcheck.ADMIN_HEALTHCHECK_QUESTION_HIDE_RULES_MODEL,
        { filters: healthcheckQuestionFilter },
        adminHealthcheck.HEALTHCHECK_QUESTION_HIDE_RULE
      ),
    () =>
      collections.getCollection(
        adminHealthcheck.ADMIN_HEALTHCHECK_QUESTION_REGION_HIDE_RULES_MODEL,
        { filters: healthcheckQuestionFilter },
        adminHealthcheck.HEALTHCHECK_QUESTION_REGION_HIDE_RULE
      ),
  ]);
}

export function reloadTemplateDocumentAndRules(docId) {
  return makeActionChain([
    () => items.loadItem(adminTemplateDoc.ADMIN_TEMPLATE_DOCUMENT_MODEL, docId),
    () =>
      collections.getCollection(
        adminTemplateDoc.ADMIN_TEMPLATE_DOCUMENT_REGION_HIDE_RULE_MODEL,
        { filters: { template_document: docId } },
        adminTemplateDoc.TEMPLATE_DOCUMENT_REGION_HIDE_RULE
      ),
    () =>
      collections.getCollection(
        adminTemplateDoc.ADMIN_TEMPLATE_DOCUMENT_MEMBERSHIP_SHOW_RULE_MODEL,
        { filters: { template_document: docId } },
        adminTemplateDoc.TEMPLATE_DOCUMENT_MEMBERSHIP_SHOW_RULE
      ),
    () =>
      collections.getCollection(
        adminTemplateDoc.ADMIN_TEMPLATE_DOCUMENT_REGION_OVERRIDES_MODEL,
        { filters: { template_document: docId } },
        adminTemplateDoc.TEMPLATE_DOCUMENT_REGION_OVERRIDES
      ),
  ]);
}

export function reloadTemplateDocumentFolderAndRules(folderId) {
  return makeActionChain([
    () =>
      items.loadItem(
        adminTemplateDoc.ADMIN_TEMPLATE_DOCUMENT_FOLDER_MODEL,
        folderId
      ),
    () =>
      collections.getCollection(
        adminTemplateDoc.ADMIN_TEMPLATE_DOCUMENT_FOLDER_HIDE_RULE_MODEL,
        { filters: { folder: folderId } },
        adminTemplateDoc.TEMPLATE_DOCUMENT_FOLDER_HIDE_RULE
      ),
  ]);
}

export function reloadTemplateDocumentCategoryAndRules(categoryId) {
  return makeActionChain([
    () =>
      collections.getCollection(
        adminTemplateDoc.ADMIN_TEMPLATE_DOCUMENT_CATEGORY_MEMBERSHIP_PERMISSION_RULE_MODEL,
        { filters: { template_category: categoryId } },
        adminTemplateDoc.TEMPLATE_DOCUMENT_CATEGORY_MEMBERSHIP_PERMISSION_RULE
      ),
    () =>
      items.loadItem(
        adminTemplateDoc.ADMIN_TEMPLATE_DOCUMENT_CATEGORY_MODEL,
        categoryId
      ),
  ]);
}

export function generateReport(reportType) {
  return makeActionChain([
    () => actions.getReportURL(reportType.get('url')),
    payload => openReportTableModal(reportType, payload),
  ]);
}

export function generateHistoryReport(model, id) {
  return makeActionChain([
    () => actions.getHistoryReportURL(model, id),
    payload =>
      openReportTableModal(
        Map({ url: payload.url, display_name: 'History Export' }),
        payload
      ),
  ]);
}

export const ACTIVITY_DATE_POPUP = 'ACTIVITY_DATE_POPUP';
export function confirmChangeDateOnActivityAndCloseModal(date, activity) {
  return makeActionChain([
    () => items.updateActivityDate(activity, date),
    () => actions.collapseUiComponent(ACTIVITY_DATE_POPUP),
    closeModal,
  ]);
}

export function generateCycleReport(cycle) {
  return makeActionChain([
    () => actions.getCycleReportURL(cycle.get('id')),
    payload => openCycleReportModal(cycle, payload),
  ]);
}

export function loadPracticesAndGetUserInfo() {
  return makeActionChain([
    () => collections.getCollection(PRACTICES),
    () => actions.getUserInfo(),
  ]);
}

export function logoutFromTimeout() {
  return makeActionChain([
    () => actions.clearUser(),
    () => routeActions.push('/login-timeout/'),
  ]);
}

export function deleteTemplateDocAlertsAndReload(doc) {
  return makeActionChain([
    () => actions.deleteTemplateDocAlerts(doc),
    () =>
      collections.getCollection(
        adminTemplateDoc.ADMIN_TEMPLATE_DOCUMENT_VERSION_MODEL,
        {
          page: 1,
          filters: { template_document: doc.get('template_document') },
        },
        'ADMINLIST_' + adminTemplateDoc.ADMIN_TEMPLATE_DOCUMENT_VERSION_MODEL
      ),
  ]);
}

export function deleteItemAndRedirectTo(type, id, path) {
  return makeActionChain([
    () => collections.deleteItem(type, id),
    () => routeActions.push(path),
  ]);
}

export function reassignPrivateDocumentsReloadAndClose(
  staffPracticeId,
  assignee
) {
  return makeActionChain([
    () => actions.reassignPrivateDocuments(staffPracticeId, assignee),
    () => collections.getCollection(PRIVATE_TEMPLATES_COPIES),
    closeModal,
  ]);
}

export function deleteTaskAndClearSelection(id) {
  return makeActionChain([
    () => collections.deleteItem(TASKS, id),
    () => items.clearItem(TASKS),
    () => routeActions.push('/page/tasks/manage/'),
  ]);
}

export function createTemplateVersionRenderDocumentAndOptionallyRedirect(
  templateId,
  content,
  makePath = null,
  extraData = null,
  documentType = null,
  shouldRedirect = true,
  andThen
) {
  let versionHolder = null;

  return makeActionChain([
    () => items.createTemplateVersion(templateId, content, extraData),
    version => {
      versionHolder = version; // HACK: you cant chain routeActions, so store the response to use later
      return (
        documentType && actions.renderDocument(documentType, versionHolder.id)
      );
    },
    () =>
      shouldRedirect &&
      extraData &&
      extraData.activity &&
      routeActions.replace(makePath(versionHolder)),
    () => andThen && andThen(),
  ]);
}

// eslint-disable-next-line  @typescript-eslint/no-empty-function
export function uploadFile(file, form, field, callback = () => {}) {
  let uploadDataHolder = null;
  return makeActionChain([
    () => actions.getUploadForm(file, form, field),
    uploadData => {
      uploadDataHolder = uploadData;
      return actions.uploadFiletoSignedUrl(uploadData.upload_form, file);
    },
    () => {
      callback(uploadDataHolder);
      return actions.completeFileUpload(uploadDataHolder.complete_url);
    },
  ]);
}

export function deleteTemplateCopyAndCloseModal(
  id,
  originalId,
  activityId = null
) {
  return makeActionChain([
    () => items.deleteTemplateCopy(id),
    () =>
      activityId
        ? items.loadItem(ACTIVITY_RELATED_TEMPLATE, activityId)
        : items.loadTemplateLatestVersion(originalId),
    closeModal,
  ]);
}

export function removeUploadedDocumentAndCloseModal(
  activityTaskId,
  uploadedFileId
) {
  return makeActionChain([
    () => actions.hideUploadedFile(activityTaskId, uploadedFileId),
    () =>
      collections.getCollection(UPLOADED_ACTIVITY_TASK_FILES, {
        page: 1,
        pageSize: 12,
      }),
    closeModal,
  ]);
}

export function downloadFromURL(url) {
  return makeActionChain([
    () => actions.fetchURL(url),
    payload => {
      window.location.href = payload.url;
    },
  ]);
}

export function downloadDigitalRecordFileFromURL(url) {
  return makeActionChain([
    () => actions.fetchURL(url),
    payload => {
      window.open(payload.url, '_blank');
    },
  ]);
}

export function updateTaskDateAndCallOnComplete(taskId, newDate, onComplete) {
  return makeActionChain([
    () => items.patchItem(TASKS, taskId, { deadline: newDate }),
    () => {
      onComplete();
    },
  ]);
}

export function updateActivityDateAndCallOnComplete(
  activity,
  date,
  onComplete
) {
  return makeActionChain([
    () => items.updateActivityDate(activity, date),
    () => {
      onComplete();
    },
  ]);
}

export function restoreAdoptedVersionAndCloseModal(id, activityId, makePath) {
  let versionHolder = null;
  return makeActionChain([
    () => items.restoreTemplateVersion(id, activityId),
    version => {
      versionHolder = version; // HACK: you cant chain routeActions, so store the response to use later
      return closeModal();
    },
    () => activityId && routeActions.push(makePath(versionHolder)),
  ]);
}

export function discardWorkingDraftLoadLatestAndThen(
  id,
  groupId,
  paramsId,
  andThen
) {
  return makeActionChain([
    () => items.discardWorkingDraft(id, groupId),
    groupId
      ? () => items.loadTemplateLatestGroupVersion(paramsId)
      : () => items.loadTemplateLatestVersion(paramsId),
    () => andThen && andThen(),
  ]);
}

export function reassignPrivateDocumentsCopiesAndRedirect(
  staffPracticeId,
  data
) {
  return makeActionChain([
    () => actions.reassignPrivateDocuments(staffPracticeId, data),
    () => closeModal(),
    () => routeActions.push('/page/documents/'),
  ]);
}

export function generateCSVReport(url, filters) {
  return makeActionChain([
    () => actions.getCSVReportURL(url, filters),
    payload => window.open(payload.url),
  ]);
}

export function updateGroupDetailsAndCloseModal(id, group) {
  return makeActionChain([
    () => items.updateItem(GROUP_DETAILS, id, group),
    closeModal,
  ]);
}

export function discardCurrentAndAdoptNewVersionAndCloseModal(
  docId,
  content,
  currentPracticeId = null,
  currentGroupId = null,
  makePathLink
) {
  let versionHolder = null;
  let makePath = null;
  return makeActionChain([
    () =>
      items.adoptTemplateVersion(
        docId,
        content,
        currentPracticeId,
        currentGroupId
      ),
    version => {
      versionHolder = version; // HACK: you cant chain routeActions, so store the version to create the makePath function
      const adoptedTemplatedDocumentID = versionHolder
        ? (versionHolder.adopted_template_document &&
            versionHolder.adopted_template_document.id) ||
          (versionHolder.group_template_document &&
            versionHolder.group_template_document.id) ||
          (versionHolder.copied_template_document &&
            versionHolder.copied_template_document.id)
        : '';
      makePath = () => makePathLink + `${adoptedTemplatedDocumentID}/`;
      return closeModal();
    },
    () => routeActions.replace(makePath(versionHolder)),
  ]);
}

export function openExitConfirmationModalAndCheckForLogin(
  document,
  content,
  groupId,
  paramsId,
  onSave,
  discard
) {
  return makeActionChain([
    () =>
      openExitConfirmationModal(
        document,
        content,
        groupId,
        paramsId,
        onSave,
        discard
      ),
    () => actions.getUserInfo(),
  ]);
}

export function deleteGroupAndCloseModal(id) {
  return makeActionChain([
    () => id, // TODO: implement delete logic here
    closeModal,
  ]);
}

export function openComparisonEditorExitConfirmationModalAndCheckForLogin(
  document,
  content,
  groupId,
  paramsId,
  onSaveAndExit,
  onSaveAndPublish,
  discard
) {
  return makeActionChain([
    () =>
      openComparisonEditorExitConfirmationModal(
        document,
        content,
        groupId,
        paramsId,
        onSaveAndExit,
        onSaveAndPublish,
        discard
      ),
    () => actions.getUserInfo(),
  ]);
}

export function sendDocReadToTeamAndCloseModal(data) {
  return makeActionChain([() => items.sendDocReadToTeam(data), closeModal]);
}

export function addReadRequestsToDocReadAndCloseModal(
  documentVersionToReadId,
  data
) {
  return makeActionChain([
    () => items.addReadRequestsToDocRead(documentVersionToReadId, data),
    () =>
      collections.getCollection(
        `${DOCUMENT_VERSIONS_TO_READ}/${documentVersionToReadId}/document-read-requests`
      ),
    closeModal,
  ]);
}

export function markDocumentAsReadAndCloseModal(documentReadRequestId) {
  return makeActionChain([
    () => items.markDocumentAsRead(documentReadRequestId),
    () => collections.getAllCollection(DASHBOARD_DOCUMENTS_READ_REQUESTS),
    closeModal,
  ]);
}

export function archiveDocumentToReadAndCloseModal(documentVersionToReadId) {
  return makeActionChain([
    () => items.archiveDocumentToRead(documentVersionToReadId),
    () => items.loadItem(DOCUMENT_VERSIONS_TO_READ, documentVersionToReadId),
    closeModal,
  ]);
}

export function archiveDocumentFolderAndCloseModal(folderId) {
  return makeActionChain([
    () => items.archiveDocumentFolder(folderId),
    () => collections.getCollection(GROUP_TEMPLATE_DOCUMENTS),
    () => collections.getAllCollection(TEMPLATE_FOLDERS),
    closeModal,
  ]);
}

export function deleteDocReadRequestAndUpdateCollections(
  docReadRequestId,
  documentVersionToReadId
) {
  return makeActionChain([
    () => actions.deleteDocumentReadRequest(docReadRequestId),
    () =>
      collections.getCollection(
        `${DOCUMENT_VERSIONS_TO_READ}/${documentVersionToReadId}/document-read-requests`
      ),
    () => collections.getCollection(DASHBOARD_DOCUMENTS_READ_REQUESTS),
  ]);
}

export function createDigitalRecordAndNavToListPage(data, recordType) {
  return makeActionChain([
    () => items.createItem(DIGITAL_RECORDS_CREATE, data),
    () => routeActions.replace(`/page/tools/records/${recordType}/`),
  ]);
}

export function updateDigitalRecordAndReloadItem(data, digitalRecordId) {
  return makeActionChain([
    () => actions.updateDigitalRecord(data, digitalRecordId),
    () => items.loadItem(DIGITAL_RECORDS_LIST, digitalRecordId),
  ]);
}

export function archiveDigitalRecordAndReloadItem(digitalRecordId) {
  return makeActionChain([
    () => actions.archiveDigitalRecord(digitalRecordId),
    () => items.loadItem(DIGITAL_RECORDS_LIST, digitalRecordId),
  ]);
}

export function reassignAndRetireMemberAndReloadPracticeMembers(
  staffPracticeId,
  data
) {
  return makeActionChain([
    () => actions.reassignAndRetireMember(staffPracticeId, data),
    () => actions.getUserInfo(),
  ]);
}

export function reassignAndSuspendMemberThenReloadPracticeMembers(
  staffPracticeId,
  data
) {
  return makeActionChain([
    () => actions.reassignAndSuspendMember(staffPracticeId, data),
    () => actions.getUserInfo(),
  ]);
}

export function reinstateMemberThenReloadPracticeMembers(staffPracticeId) {
  return makeActionChain([
    () => actions.reinstateMember(staffPracticeId),
    () => actions.getUserInfo(),
  ]);
}

export function updateWorkingDraftAndRedirect(workingDraftId, to) {
  return makeActionChain([
    () => actions.updateWorkingDraft(workingDraftId),
    () => closeModal(),
    () => routeActions.push(to),
  ]);
}

export function updateWorkingDraftAndReload(workingDraftId) {
  return makeActionChain([
    () => actions.updateWorkingDraft(workingDraftId),
    () => closeModal(),
    () => window.location.reload(),
  ]);
}

export function addNewChecklistAndCloseModal(type, data, checklistType) {
  return makeActionChain([
    () => items.createItem(type, data),
    () => actions.getDigitalRecordNavigationList(),
    () => closeModal(),
    () => collections.getCollection(CHECKLIST_RECORD_GROUP),
    payload => {
      const recordGroupsOfType = payload.filter(
        item => item.record_type === checklistType
      );
      const newChecklistItem =
        recordGroupsOfType[recordGroupsOfType.length - 1];
      return routeActions.replace(
        `/page/tools/records/checklist/${newChecklistItem.id}/${newChecklistItem.current_date_id}`
      );
    },
  ]);
}

export function updateDynamicChecklistAndCloseModal(recordGroupId, date) {
  return makeActionChain([
    () => actions.updateDynamicChecklistSnapshot(recordGroupId, date),
    () => actions.getDigitalRecordChecklist(recordGroupId, date),
    () => closeModal(),
  ]);
}

export function createUpdateDigitalRecordChecklistAndAddRelatedFiles(recordGroupId, date, data, checklistForm) {
  return makeActionChain([
    () => actions.createUpdateDigitalRecordChecklist(recordGroupId, date, data, checklistForm),
    () => actions.updateChecklistRelatedFiles(recordGroupId, date, data),
  ]);
}

export function archiveChecklistAndCloseModal(recordGroupId) {
  return makeActionChain([
    () => actions.archiveDigitalRecordGroup(recordGroupId),
    () => actions.getDigitalRecordNavigationList(),
    () => closeModal(),
    () => collections.getCollection(CHECKLIST_RECORD_GROUP),
    () => routeActions.replace(`/page/tools/records/`),
  ]);
}

export function createDocumentReviewAndCloseModal(adoptedDocumentId, data) {
  return makeActionChain([
    () => actions.createDocumentReview(adoptedDocumentId, data),
    () => items.loadTemplateLatestVersion(adoptedDocumentId),
    () => closeModal(),
  ]);
}

export function completeDocumentReviewAndCloseModal(adoptedDocumentId, data) {
  return makeActionChain([
    () => actions.completeDocumentReview(adoptedDocumentId, data),
    () => items.loadTemplateLatestVersion(adoptedDocumentId),
    () => closeModal(),
  ]);
}

export function adminSubmitAndInheritGroupsFunctionality(
  practiceId,
  practiceGroupId,
  patchItemParams
) {
  return makeActionChain([
    () => items.patchItem(...patchItemParams),
    () => actions.inheritGroupsFunctionality(practiceGroupId, practiceId),
  ]);
}

export function adminSubmitWithoutInheritGroupsFunctionality(patchItemParams) {
  return makeActionChain([
    () => closeModal(),
    () => items.patchItem(...patchItemParams),
  ]);
}
