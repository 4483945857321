import { EMPTY_DATA } from './constants';

export function transformChartData (data) {
  return data ? [
    ['Status', 'Percentage'],
    ['Completed', data.get('COMPLETED') || 0],
    ['In-progress', data.get('IN_PROGRESS') || 0],
    ['Outstanding', data.get('OVERDUE') || 0],
  ] : EMPTY_DATA;
}
