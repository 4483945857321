export const TABS = [
  {
    path: 'dashboard',
    label: 'dashboard',
    counter: 'overDueActivityCount',
  },
  {
    path: 'calendar',
    label: 'calendar',
    counter: 'alertsCount',
  },
  {
    path: 'tasks',
    label: 'tasks',
    applicationOnly: true,
  },
  {
    path: 'documents',
    label: 'documents',
  },
  {
    path: 'reports',
    label: 'reports',
    applicationOnly: true,
    adminOnly: true,
  },
  {
    path: 'tools',
    label: 'digital tools',
  },
  {
    path: 'practices',
    label: 'practice',
  },
  {
    path: 'help',
    label: 'help',
    iconType: 'comments',
    iconClassName: 'white-icon title ml-1',
  },
];

export const getGroupsTabConfig = (hasGroupDocumentsAccess, hasAccessToGroupConfiguration) =>{
  const GROUPS_TABS = [
    {
      path: 'groups/insights',
      label: 'insights',
    },
    {
      path: 'groups/group-details',
      label: 'group details',
    },
    {
      path: 'groups/group-tasks',
      label: 'group tasks',
    },
  ];

  if (hasGroupDocumentsAccess) {
    GROUPS_TABS.splice(2, 0, {
      path: 'groups/group-documents',
      label: 'group documents',
      counter: 'groupDocumentsAlertsCount',
    });
  }

  if (hasAccessToGroupConfiguration) {
    GROUPS_TABS.push({
      path: 'groups/group-config',
      label: 'group config',
    });
  }

  return GROUPS_TABS;
};
