import { List } from 'immutable';
import { isAdmin } from '^/models/user';
import { getSetupUrl, getSubsequentCycleSetupStageUrl } from '^/consts/setup';
import { startsWith } from '^/utils';

export const isOnAppPage = path =>
  startsWith(path, '/page');

export const isOnLockedDemoPage = path =>
  startsWith(path, '/expired');

export const isOnAdminPage = path =>
  startsWith(path, '/admin');

export const isOnStyleguidePage = path =>
  startsWith(path, '/styleguide');

export const isOnPolicyPage = path =>
  startsWith(path, '/cookie-policy') || startsWith(path, '/privacy-policy');

export const isOnLoginFailedPage = path =>
  startsWith(path, '/login-failed')

export const isOnInvalidInvitePage = path =>
  startsWith(path, '/invalid-invite')

export const isOnUserSuspendedPage = path =>
  startsWith(path, '/user-suspended')

export const isAtBaseRoute = path =>
  path === '/' || path === '';

export const isOnSetupPage = path =>
  startsWith(path, '/page/set-up/');

export const isOnSubsequentCycleSetupPage = path =>
  startsWith(path, '/page/setup-cycle/');

export const isOnSetupCompletePage = path =>
  startsWith(path, '/page/setup-complete');

export const isOnPracticeAdminPage = path =>
  List([
    '/page/reports/',
  ]).contains(path);

export const isOnGroupAdminPage = path => startsWith(path, '/page/groups');

export const isOnDashboardPage = path =>
  path === '/page/dashboard/';

export const isOnUserProfilePage = path => startsWith(path, '/page/profile');

export const hasAccessToGroupDashboard = user =>
  user && user.getIn(['staffdetail', 'current_practice', 'group', 'has_group_dashboard_access']);

export const shouldRedirectToLogin = (isLoggedIn, path) =>
  !isLoggedIn &&
  (
    isOnAppPage(path) ||
    isAtBaseRoute(path) ||
    isOnAdminPage(path)
  );


export const shouldRedirectToSsoLogin = (isSSOAuthServiceEnabled, isLoggedIn, path) =>
  isSSOAuthServiceEnabled && (
    shouldRedirectToLogin(isLoggedIn, path)
  );

// if there is a user and they need to setup and are not on the right page already
export const shouldRedirectToSetup = (isLoggedIn, user, path) =>
  isLoggedIn && user &&
    !isOnStyleguidePage(path) &&
    getSetupUrl(user) &&
    path.indexOf(getSetupUrl(user)) === -1
    && !isOnSetupCompletePage(path)
    && !isOnUserSuspendedPage(path)
    && !isOnLockedDemoPage(path);

// if the user is an administrator and they are at not on an admin page or the styleguide
export const shouldRedirectToAdmin = (isLoggedIn, user, path) =>
  isLoggedIn && user &&
    isAdmin(user) &&
    !isOnAdminPage(path) &&
    !isOnPolicyPage(path) &&
    !isOnLockedDemoPage(path) &&
    !isOnLoginFailedPage(path) &&
    !isOnInvalidInvitePage(path) &&
    !isOnUserSuspendedPage(path) &&
    !isOnStyleguidePage(path);

// user is not on an app page or is on a setup page when they shouldn't be or is on an admin page when not an admin
const isOnWrongPage = (user, path, isPracticeAdmin, isGroupAdmin) =>
  !isOnAppPage(path) ||
  (!getSetupUrl(user) && isOnSetupPage(path)) ||
  (!isPracticeAdmin && (
    isOnPracticeAdminPage(path) || isOnSubsequentCycleSetupPage(path)
  )) ||
  ((!isGroupAdmin || !hasAccessToGroupDashboard(user)) && isOnGroupAdminPage(path));

// if the user is not an administrator and not at the styleguide and is on a page they shouldn't be
export const shouldRedirectDashboard = (isLoggedIn, user, path, isPracticeAdmin, isGroupAdmin) =>
  isLoggedIn && user &&
    !isAdmin(user) &&
    !isOnStyleguidePage(path) &&
    !isOnPolicyPage(path) &&
    !isOnLockedDemoPage(path) &&
    !isOnLoginFailedPage(path) &&
    !isOnInvalidInvitePage(path) &&
    !isOnUserSuspendedPage(path) &&
    isOnWrongPage(user, path, isPracticeAdmin, isGroupAdmin);

export const shouldRedirectToDifferentCycleSetupPage = (isLoggedIn, user, path, isPracticeAdmin) =>
  isPracticeAdmin && isLoggedIn && user &&
    !isAdmin(user) &&
    user.getIn(['staffdetail', 'current_practice', 'id']) &&
    isOnSubsequentCycleSetupPage(path) &&
    getSubsequentCycleSetupStageUrl(user, user.getIn(['staffdetail', 'current_practice', 'id'])) &&
    getSubsequentCycleSetupStageUrl(user, user.getIn(['staffdetail', 'current_practice', 'id'])) !== path;
  
export const shouldRedirectToGroupsDashboard = (isLoggedIn, user, path) => {
  return (
    isLoggedIn &&
    user &&
    user.get('is_insights_only_groups_user') &&
    !(isOnGroupAdminPage(path) || isOnUserProfilePage(path))
  );
};

export const shouldRedirectToLockedDemoUserPage = (isLoggedIn, user, path) => {
  return isLoggedIn && user && user.get('has_demo_expired') && !isOnSetupPage(path) && !isOnLockedDemoPage(path);
};
