import { routeActions, UPDATE_LOCATION } from 'react-router-redux';
import { List, fromJS } from 'immutable';
import * as actions from '^/actions/actions';
import {
  getSSOAuthRedirectWithNextPath,
  getUserActionLogoutUrl,
  IS_USING_SSO_AUTH_SERVICE,
  DEVELOPMENT_LOGIN_PATH,
} from '^/consts/single-sign-on';
import {
  shouldRedirectToLogin,
  shouldRedirectToAdmin,
  shouldRedirectDashboard,
  shouldRedirectToLockedDemoUserPage,
  shouldRedirectToSetup,
  shouldRedirectToDifferentCycleSetupPage,
  shouldRedirectToGroupsDashboard,
  shouldRedirectToSsoLogin
} from '^/consts/redirectRules';
import * as utils from '^/utils';
import { getSetupUrl, getSubsequentCycleSetupStageUrl } from '^/consts/setup';
import { maybeInitializePendo } from '^/middleware/authentication';
import {
  isLoggedOnUserAdminOfCurrentPractice,
  isLoggedOnUserAdminOfCurrentGroup,
} from '^/stateHelpers';


const PRACTICE_CHANGE_SAFE_PATH_MATCHERS = List([
  /^\/page\/dashboard\/$/,
  /^\/page\/calendar\/$/,
  /^\/page\/news\/$/,
  /^\/page\/tasks\/$/,
  /^\/page\/practices\/[0-9a-f-]+\//,
]);

const getPath = (state) => state.routing && state.routing.location && state.routing.location.pathname;

export default store => next => action => {
  const state = store.getState();
  const isLoggedIn = utils.isLoggedIn(state);
  const path = getPath(state);
  const user = fromJS(action.payload) || state.userProfile;

  switch (action.type) {
    case actions.LOGIN.SUCCESS:

      const intendedLocation = state.rememberedLocation;
      const intendedPath = intendedLocation && intendedLocation.get('path');
      const location = (
        intendedPath && !utils.startsWith(intendedPath, '/login-splash-page')
      ) ? intendedPath : '/page/dashboard/';

      next(action); // We need user's logged in status to get pushed into state before we attempt to redirect
      store.dispatch(routeActions.push(location));
      return null;

    case actions.LOGOUT.SUCCESS:
      store.dispatch(actions.clearUser());
      return window.location.replace(getUserActionLogoutUrl(action));

    case actions.SWITCH_PRACTICE.SUCCESS:
      const shouldRedirect = !PRACTICE_CHANGE_SAFE_PATH_MATCHERS.some(matcher => matcher.test(path));

      maybeInitializePendo(
        user.set('is_impersonate', state.userProfile.get('is_impersonate'))
      );

      if (shouldRedirect) {
        store.dispatch(routeActions.push('/page/dashboard/'));
      }
      break;

    case actions.INITIATE_TRANSACTION.SUCCESS:
      const { status, NextURL } = action.payload;
      if (status === 'OK') {
        if (NextURL) {
          window.location = NextURL;
        } else {
          store.dispatch(actions.getUserInfo());
        }
      }
      break;

    case UPDATE_LOCATION:
      if (shouldRedirectToSsoLogin(IS_USING_SSO_AUTH_SERVICE, isLoggedIn, action.payload.pathname)) {
        return window.location.replace(getSSOAuthRedirectWithNextPath(action.payload.pathname));
      } else if (shouldRedirectToLogin(isLoggedIn, action.payload.pathname)) {
        return window.location.replace(DEVELOPMENT_LOGIN_PATH);
      } else if (shouldRedirectToGroupsDashboard(isLoggedIn, state.userProfile, action.payload.pathname)) {
        store.dispatch(routeActions.replace('/page/groups/insights/'));
      }
      break;
    case actions.APP_INIT:
      if (shouldRedirectToSsoLogin(IS_USING_SSO_AUTH_SERVICE, isLoggedIn, path)) {
        return window.location.replace(getSSOAuthRedirectWithNextPath(path));
      } else if (shouldRedirectToLogin(isLoggedIn, path)) {
        return window.location.replace(DEVELOPMENT_LOGIN_PATH);
      }
      break;
    case actions.GET_USER_INFO.SUCCESS:
    case actions.CHECK_REDIRECTS:
      if (shouldRedirectToAdmin(isLoggedIn, user, path)) {
        store.dispatch(routeActions.replace('/admin/'));
      } else if (shouldRedirectToLockedDemoUserPage(isLoggedIn, user, path)) {
        store.dispatch(routeActions.replace('/expired/'));
      } else if (shouldRedirectToSetup(isLoggedIn, user, path)) {
        store.dispatch(routeActions.replace(getSetupUrl(user)));
      } else if (shouldRedirectToGroupsDashboard(isLoggedIn, user, path)) {
        store.dispatch(routeActions.replace('/page/groups/insights/'));
      } else if (shouldRedirectToDifferentCycleSetupPage(
        isLoggedIn,
        user,
        path,
        isLoggedOnUserAdminOfCurrentPractice(state))
      ) {
        store.dispatch(routeActions.replace(
          getSubsequentCycleSetupStageUrl(user, user.getIn(['staffdetail', 'current_practice', 'id']))
        ));
      } else if (
        shouldRedirectDashboard(
          isLoggedIn,
          user,
          path,
          isLoggedOnUserAdminOfCurrentPractice({ userProfile: user }),
          isLoggedOnUserAdminOfCurrentGroup({ userProfile: user })
        )
      ) {
        store.dispatch(routeActions.replace('/page/dashboard/'));
      }
      break;
  }

  return next(action);
};
