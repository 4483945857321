import React from 'react';
import moment from 'moment';
import { Link } from 'react-router';
import { Editor as TinyMCE } from '@tinymce/tinymce-react';
import { List } from 'immutable';
import PureComponent from '^/components/common/PureComponent';

import {
  isCopiable,
  getBaseTemplateDocument,
  DOCUMENT_TYPE,
  getExportFromTemplateVersion,
} from '^/models/documents';
import { TEMPLATE_DOCUMENT_VERSION } from '^/consts/collectionKeys';
import { formatDate, isGroupsAppSection, getDocumentReviewTitle } from '^/utils';
import { isPending, hasSucceeded } from '^/consts/responseStates';
import { getConfig, TINYMCE_ID } from '^/consts/tinyMCE';
import LiveButton from '^/components/app/forms/LiveButton';
import FileUpload from '^/components/app/forms/FileUpload';
import GroupTemplateDocumentNewFileVersionButtons from '^/components/app/groups/group-documents/GroupTemplateDocumentNewFileVersionButtons';;
import Icon from '^/components/app/content/Icon';
import { DocumentEditingMayDisallow, MaybeDisplayDocumentTakeOver } from '^/components/app/perms/DocumentEditingMayDisallow';
import DownloadDocumentButton from '^/components/app/activities/documents/DownloadDocumentButton';
import PermissionsComponent from '^/components/app/perms/PermissionsComponent';
import { isNotReadOnlyInCurrentPractice } from '^/models/user';
import UpdateToIfLaterVersion from './UpdateToIfLaterVersion';
import SeeCopiesLink from './SeeCopiesLink';
import TemplateDocumentTypeIcon from './TemplateDocumentTypeIcon';
import TemplateDocumentTerms from './TemplateDocumentTerms';
import EditableFieldText from '^/components/app/widgets/EditableFieldText';
import HelpBlock from '^/components/app/widgets/HelpBlock';
import ManageCollaborators from './ManageCollaborators';
import ReassignPrivateDocumentOwnership from '^/components/app/template-documents/ReassignPrivateDocumentOwnership';

export const VersionAndDate = ({version_number, created}) => (
  <span>
    <span className="heading-version">
        v{version_number}
      </span> <span className="heading-date">
        ({formatDate(created)})
      </span>
  </span>
);

const TemplateAdoptedInfo = ({adoptedTemplateDocumentVersion}) => {
  const { modified, adopted_template_document, group_template_document } = adoptedTemplateDocumentVersion.toObject();

  let created, name, codeModified;
  if (adopted_template_document) {
    created = adopted_template_document.get('created');
    codeModified = adopted_template_document.getIn(['template_document_version', 'modified']);
    name = adopted_template_document.getIn(['adoptee_practice', 'name']);
  }
  else {
    created = group_template_document.get('created');
    codeModified = group_template_document.getIn(['template_document_version', 'modified']);
    name = group_template_document.getIn(['adoptee_practice_group', 'name']);
  }

  const wasReviewed = moment(modified).isAfter(moment(codeModified));
  const reviewWord = wasReviewed ? 'reviewed' : 'updated';
  const reviewDate = wasReviewed ? modified : codeModified;

  return (
    <div className="light-text small-text grey">
      Adopted by <b>{name}</b> <span className="heading-date">
        {formatDate(created)} (last {reviewWord} {formatDate(reviewDate)})
      </span>
    </div>
  );
};

export const TemplateAdoptionInfo = ({onClick}) => {
  return (
    <div>
      <a className="inline-link" onClick={onClick}>
        See Practice Adoption
      </a>
    </div>
  );
};

const AttachedDocumentInfo = ({attachedDocument}) => {
  const { modified } = attachedDocument.toObject();

  return (
    <div className="light-text small-text grey mt-1-2 mb-1">
      Last updated on {moment(modified).format('DD/MM/YYYY')} for this activity
    </div>
  );
};

const ActivityLink = ({activity}) => {
  const { current_cycle_activity, name } = activity.toObject();

  return current_cycle_activity ? (
    <Link to={`/page/activities/${current_cycle_activity}/`} target="_blank" className="underline">
      {name}
    </Link>
  ) : (
    <span title="No activity this cycle">{name}</span>
  );
};

export const TemplateVersionInfo = ({
  baseTemplateDocumentVersion, adoptedTemplateDocumentVersion, attachedDocument, editingGroupDocument,
  openPracticeAdoptionModal
}) => {
  const templateDocument = getBaseTemplateDocument(baseTemplateDocumentVersion);
  const templateDocumentVersion = editingGroupDocument && adoptedTemplateDocumentVersion || baseTemplateDocumentVersion;
  const { version_number, is_adoptable } = templateDocumentVersion.toObject();
  const { created } = baseTemplateDocumentVersion.toObject();

  return (
    <div>
      <h2>
        {templateDocument.get('name')} <VersionAndDate version_number={version_number} created={created} />
        <TemplateDocumentTypeIcon templateDocument={templateDocument} large displayRight />
      </h2>

      {adoptedTemplateDocumentVersion && (
        <TemplateAdoptedInfo adoptedTemplateDocumentVersion={adoptedTemplateDocumentVersion} />
      )}
      {editingGroupDocument && openPracticeAdoptionModal && is_adoptable && (
        <TemplateAdoptionInfo
          onClick={() => openPracticeAdoptionModal(templateDocument)}
        />
      )}

      {attachedDocument && <AttachedDocumentInfo attachedDocument={attachedDocument} />}
    </div>
  );
};

export const TemplateInfo = ({baseTemplateDocumentVersion, copy, isCopyOwner, readOnly, isWorkingVersion}) => {
  const baseTemplateDocument = getBaseTemplateDocument(baseTemplateDocumentVersion);
  const { code, folder, template_category, help_text, is_secure_document } = baseTemplateDocument.toObject();
  const categoryName = template_category.get('name');
  const folderNumber = folder ? folder.get('number') : 'None';
  const isSecureCopy = Boolean(copy && is_secure_document);

  return (
    <table className="mt-1-2 mb-1-2">
      <tbody>
        {Boolean(help_text) && (
          <tr>
            <td><span className="grey">{help_text}</span></td>
          </tr>
        )}
        {Boolean(copy && copy.get('name')) &&
          <tr>
            <td>
              <EditableFieldText
                fieldName="name"
                key={copy.get('id')}
                model={TEMPLATE_DOCUMENT_VERSION}
                object={copy}
              >
                <span className="grey">Name: </span>
                <b className="bold-text">{copy.get('name')}</b>
              </EditableFieldText>
            </td>
          </tr>
        }
        <tr>
          <td>
            <span className="grey">Category: </span>
            <b className="bold-text">{categoryName}</b>
          </td>
        </tr>
        <tr>
          <td>
            <span className="grey">Code: </span>
            <b className="bold-text">{code}</b>
          </td>
        </tr>
        <tr>
          <td>
            <span className="grey">Folder: </span>
            <b className="bold-text">{folderNumber}</b>
          </td>
        </tr>
        {isSecureCopy && (
          <tr>
            <td>
              <span className="grey">Owner: </span>
              <b className="bold-text">{copy.getIn(['owner', 'staffdetail', 'full_name'])}</b>
              {isCopyOwner && (
                <ReassignPrivateDocumentOwnership
                  copiedDocumentId={copy.get('id')}
                  isWorkingVersion={isWorkingVersion}
                />
              )}
            </td>
          </tr>
        )}
        {isSecureCopy && (
          <tr>
            <td>
              <span className="grey">Collaborators: </span>
              <b className="bold-text">{copy.get('collaborators', List()).count()}</b>
              {!readOnly && isCopyOwner && (
                <ManageCollaborators
                  currentCollaborators={copy.get('collaborators', List())}
                  copiedDocumentId={copy.get('id')}
                />
              )}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

const NUM_ACTIVITIES_TO_SHOW = 10;

const TemplateActivityInfo = ({template_activities}) => (
  <div className="grey small-text">
    <p>Related to activities:</p>
    {template_activities.size ? (
      <div>
        <ul className="small-text list-no-indent">
          {template_activities.slice(0, NUM_ACTIVITIES_TO_SHOW).map((activity, idx) =>
            <li key={idx}>
              <ActivityLink activity={activity} />
            </li>
          )}
        </ul>
        {template_activities.size > NUM_ACTIVITIES_TO_SHOW && (
          <p className="text-right"><em>...and {template_activities.size - NUM_ACTIVITIES_TO_SHOW} others.</em></p>
        )}
      </div>
    ) : (
      <p>
        <em>No related activities.</em>
      </p>
    )}
  </div>
);

export const TemplateHeader = props => {
  const {
    baseTemplateDocumentVersion,
    isGroupsApp,
    resetResponseAndOpenGroupDocumentModal,
    isEditingGroupCreatedDocument,
  } = props;
  const baseTemplateDocument = getBaseTemplateDocument(baseTemplateDocumentVersion);
  const { template_activities } = baseTemplateDocument.toObject();

  return (
    <div>
      <div className="col-2-3">
        <TemplateVersionInfo {...props} />
        <TemplateInfo {...props} />
        {isEditingGroupCreatedDocument && (
          <a
            className="inline-link mb-1 display-block"
            onClick={() => resetResponseAndOpenGroupDocumentModal(baseTemplateDocument)}
          >
            Edit Document Attributes
          </a>
        )}
      </div>

      <div className="col-1-3">
        {!isGroupsApp && <TemplateActivityInfo template_activities={template_activities} />}
      </div>
    </div>
  );
};

const FileDocumentWrapper = ({children}) =>
  <div>
    <div className="alert alert-info mt-1 mb-1 p-1">
      <div className="row">
        <div className="col-1-2 col-1-4-offset">
          <div className="p-1">
            <Icon type="file-text-o" className="large-icon hidden-m pull-right" />
            <div className="floating-large-icon-content-wrapper">
              {children}
            </div>
           </div>
        </div>
      </div>
    </div>
  </div>;

const FileDocumentPreview = ({content_file, name, version}) =>
  <FileDocumentWrapper>
    <h4 className="mt-1-2 mb-1">{name}</h4>
    <p className="mt-1-2 mb-1">Version {version}</p>
    <p><a className="btn btn-default" href={content_file} target="_blank">Download</a></p>
  </FileDocumentWrapper>;

const Editor = ({
  content,
  content_file,
  content_file_name,
  isLandscape,
  setEditorContent,
  editorContent,
  resetSaveResponses,
  isEditingGroupCreatedFileDocument,
  updateGroupTemplateDocumentFileVersion,
  groupTemplateDocumentNewFileVersionResponse,
  groupTemplateDocumentCurrentFileVersionResponse,
}) => {
  if (content) {
    return (
      <div className="mt-1 mb-1">
        <TinyMCE
          id={TINYMCE_ID}
          initialValue={content}
          init={Object.assign({}, getConfig(isLandscape), {
            selector: '#' + TINYMCE_ID
          })}
          onChange={evt => {
            setEditorContent(evt.target.getContent());
            resetSaveResponses();
          }}
        />
      </div>
    );
  }

  if (content_file) {
    return (
      <div>
        <FileDocumentWrapper>
          <FileUpload
            className="image-field"
            src={editorContent || content_file}
            display={src =>
              src && <a href={src.preview || src} target="_blank">{src.name || content_file_name || 'Download'}</a>
            }
            setPendingUpload={(file) => setEditorContent(file)}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onSubmit={() => {}}
          />
        </FileDocumentWrapper>
        {isEditingGroupCreatedFileDocument && (
            <GroupTemplateDocumentNewFileVersionButtons
              updateVersion={updateGroupTemplateDocumentFileVersion}
              newVersionResponse={groupTemplateDocumentNewFileVersionResponse}
              currentVersionResponse={groupTemplateDocumentCurrentFileVersionResponse}
              editorContent={editorContent}
            />
          )
        }
      </div>
    );
  }

  return <p>Cannot display document</p>;
};

const Preview = ({
  content,
  content_file,
  isLandscape,
  version,
  name,
  header,
  footer,
  isEditingGroupCreatedFileDocument,
  startEditingGroupCreatedFileDocument,
}) => {
  if (content) {
    return (
      <div className="row mt-1 mb-1 ml-0 mr-0 document-bg">
        <div className="col-1">
          <div className={'document' + (isLandscape ? ' landscape' : '')}>
            <div
              className="document-content no-select"
              dangerouslySetInnerHTML={{__html: header}}
            />
            <div
              className="document-content no-select"
              dangerouslySetInnerHTML={{__html: content}}
            />
            <div
              className="document-content no-select"
              dangerouslySetInnerHTML={{__html: footer}}
            />
          </div>
        </div>
      </div>
    );
  }

  if (content_file) {
    return (
      <div>
        <FileDocumentPreview
          content_file={content_file}
          name={name}
          version={version}
        />
        {isEditingGroupCreatedFileDocument && (
          <button
            className="btn btn-primary mb-1"
            onClick={startEditingGroupCreatedFileDocument}
          >
            Edit document
          </button>
        )}
      </div>
    );
  }

  return <p>Cannot display document</p>;
};

const NewVersionAndWorkingVersionActionButtons = ({
  canSave,
  onSave,
  saveResponse,
  editingGroupDocument,
  onSaveAndClose,
  isGroupAdminEditingGroupDocument,
  onSaveWorking,
  saveAsWorkingDraftResponse,
  discard,
  onCancel,
}) => {
  return (
    <span>
      {canSave && (
        <span>
          <LiveButton className="btn btn-primary mr-1" onClick={() => onSave()} response={saveResponse}>
            {editingGroupDocument ? 'Publish As New Version' : 'Save'}
          </LiveButton>
          <LiveButton
            className="btn btn-primary mr-1"
            onClick={() => onSaveAndClose()}
            response={saveResponse}
          >
            {editingGroupDocument ? 'Publish As New Version and Close' : 'Save and Close'}
          </LiveButton>
        </span>
      )}
      {isGroupAdminEditingGroupDocument ? (
        <span className="inline">
          <LiveButton
            className="btn btn-primary mr-1"
            onClick={onSaveWorking}
            response={saveAsWorkingDraftResponse}
          >
            Save As Working Version
          </LiveButton>
          <LiveButton className="btn btn-warning mr-1" onClick={discard}>
            Discard
          </LiveButton>
        </span>
      ) : (
        <button className="btn btn-default mr-1" onClick={onCancel} >
          Discard Edits
        </button>
      )}
    </span>
  );
}

export function getTemplateDocumentVersion(doc, isAttached) {
  return isAttached ? doc.get('template_document_version') : doc;
}

export function getDocumentExportDownLoadURL(templateDocumentVersion) {
  const documentExport = getExportFromTemplateVersion(templateDocumentVersion);
  return documentExport && documentExport.get('download_url');
}

class DocumentEditor extends PureComponent {
  componentDidMount() {
    if (this.props.isEditing && this.props.startEditing) {
      this.props.startEditing();
    }
  }

  render() {
    const {
      isEditing,
      startEditing,
      onCancel,
      onSave,
      onSaveAndClose,
      onSaveCopy,
      onSaveWorking,
      setEditorContent,
      saveResponse,
      doc,
      readOnly,
      editingGroupDocument,
      editorContent,
      openPracticeAdoptionModal,
      resetResponseAndOpenGroupDocumentModal,
      showingCopies,
      toggleShowingCopies,
      userId,
      hideToggleButton,
      discard,
      autosaveAsWorkingDraftResponse,
      isGroupAdmin,
      saveAsWorkingDraftResponse,
      isAdmin,
      resetSaveResponses,
      restoreAdoptedVersion,
      isPracticeAdmin,
      currentPathname,
      isEditingGroupCreatedDocument,
      updateGroupTemplateDocumentFileVersion,
      isEditingGroupCreatedFileDocument,
      startEditingGroupCreatedFileDocument,
      groupTemplateDocumentNewFileVersionResponse,
      groupTemplateDocumentCurrentFileVersionResponse,
      currentPracticeId,
      sendDocReadToTeamResponse,
      openDocumentTakeOverModal,
      hasAccessToDocumentReviews,
      openDocumentReviewModal,
      isGroupsApp,
      hasDocumentsToReadAccess,
    } = this.props;

    /* You might be editing a:
      - HTML or File document
      - editing or viewing
      - Practice admin/team member:
        - editing a CODE or group doc -> SAVE adopts to practice
        - editing a practice adopted doc -> SAVE
        - editing a copiable CODE/group doc -> SAVE AS NEW COPY copies to practice
        - editing a copied doc -> either SAVE, or SAVE AS COPY creating new copy
      - Group admin:
        - editing a CODE doc -> SAVE adopts to group
        - editing a group doc -> SAVE
    */

    const {
      content,
      content_file,
      content_file_name,
      activity,
      version_number,
      group_template_document,
      working_version,
      header,
      footer,
    } = doc.toObject();

    const pristine = (content === editorContent) || content === null;

    const isAttached = !!activity;
    const templateDocumentVersion = getTemplateDocumentVersion(doc, isAttached);

    const adoptedDocument = templateDocumentVersion.get(
      editingGroupDocument ? 'group_template_document' : 'adopted_template_document'
    );
    const isAdopted = Boolean(adoptedDocument);
    const copiedDocument = templateDocumentVersion.get('copied_template_document');
    const isCopied = Boolean(copiedDocument);

    const sourceVersion = templateDocumentVersion.get('source_version');

    const templateDocumentVersionReadRequests = templateDocumentVersion.get('doc_read_requests')

    const baseTemplateDocument = adoptedDocument || copiedDocument || sourceVersion;
    const baseTemplateDocumentVersion = baseTemplateDocument
      ? baseTemplateDocument.get('template_document_version', sourceVersion)
      : templateDocumentVersion;
    const name = getBaseTemplateDocument(baseTemplateDocumentVersion).get('name');
    const isLandscape = getBaseTemplateDocument(baseTemplateDocumentVersion).get('is_landscape');

    const isCopiableDoc = isCopiable(templateDocumentVersion);
    const hasCopiedTemplates = baseTemplateDocumentVersion.get('has_copied_templates');
    const canSave = !isCopiableDoc || isCopied || editingGroupDocument;

    const userCurrentlyEditing = working_version && working_version.getIn(['user', 'staffdetail', 'full_name']);
    const locked = working_version && working_version.getIn(['user', 'id']) !== userId;
    const templateDocumentKind = getBaseTemplateDocument(doc).get('kind');

    const editGroupDocumentLink =
      `/page/documents/group/${getBaseTemplateDocument(baseTemplateDocumentVersion).get('id')}/`;

    const isSaveCopy = !editingGroupDocument && isCopiableDoc && !(isCopied && copiedDocument.get('activity'));
    const isWorkingVersion = Boolean(working_version);

    const docReadsEnabledForTemplateDocument = getBaseTemplateDocument(doc).get(
      'enable_doc_reads'
    );
    const canSendDocReadToTeam =
      !group_template_document &&
      (isAdopted || isCopied) &&
      docReadsEnabledForTemplateDocument;

    const documentReview = adoptedDocument && adoptedDocument.get('document_review')

    const isGroupsAppOrGroupDocuments = isGroupsApp || group_template_document;

    return (
      <DocumentEditingMayDisallow templateVersion={baseTemplateDocumentVersion}>
        <div className="row">
          <TemplateHeader
            baseTemplateDocumentVersion={baseTemplateDocumentVersion}
            adoptedTemplateDocumentVersion={isAdopted && templateDocumentVersion}
            attachedDocument={isAttached && this.props.templateDocumentVersion}
            editingGroupDocument={editingGroupDocument}
            openPracticeAdoptionModal={openPracticeAdoptionModal}
            resetResponseAndOpenGroupDocumentModal={resetResponseAndOpenGroupDocumentModal}
            copy={isCopied && copiedDocument}
            isCopyOwner={isCopied && copiedDocument.getIn(['owner', 'id']) === userId}
            readOnly={readOnly}
            isWorkingVersion={isWorkingVersion}
            isGroupsApp={isGroupsAppSection(currentPathname)}
            isEditingGroupCreatedDocument={isEditingGroupCreatedDocument}
          />
        </div>

        {!isEditing && !isCopied && hasCopiedTemplates && toggleShowingCopies && !editingGroupDocument && (
          <div className="row mb-1-2">
            <div className="col-1">
              <SeeCopiesLink
                hasCopiedTemplates={hasCopiedTemplates}
                isExpanded={showingCopies}
                toggleExpanded={toggleShowingCopies}
                hideToggleButton={hideToggleButton}
                documentKind={templateDocumentKind}
                isAdmin={isAdmin}
                originalId={templateDocumentVersion.get('id')}
                documentVersionId={baseTemplateDocumentVersion.get('id')}
              />
            </div>
          </div>
        )}

        {editingGroupDocument &&
          <HelpBlock iconType="info-circle" className="mb-1">
            <p>
              You are currently editing the <strong>group</strong> version of this template document
            </p>
          </HelpBlock>
        }
        {hasAccessToDocumentReviews && adoptedDocument && !isGroupsAppOrGroupDocuments && !isEditing && !documentReview &&
          <HelpBlock iconType="info-circle" className="mb-1">
            <p>
              You have not yet created a review. Please do so now by clicking on the button below.
            </p>
          </HelpBlock>
        }
        <div className="loading-ellipsis-wrapper">
          {isEditing && isPending(autosaveAsWorkingDraftResponse) && (
            <span className="loading-ellipsis" >Saving</span>
          )}
        </div>

        <div className="row">
          <div className="col-1 pull-left">
            {!readOnly && (
              isEditing ? (
                <span>
                  {isSaveCopy && (
                    <LiveButton className="btn btn-primary mr-1" onClick={() => onSaveCopy()} response={saveResponse}>
                      {isCopied ? 'Save copy' : 'Create new copy'}
                    </LiveButton>
                  )}

                  {!isEditingGroupCreatedFileDocument && (
                    <NewVersionAndWorkingVersionActionButtons
                      canSave={canSave}
                      onSave={onSave}
                      saveResponse={saveResponse}
                      editingGroupDocument={editingGroupDocument}
                      onSaveAndClose={onSaveAndClose}
                      isGroupAdminEditingGroupDocument={isGroupAdmin && editingGroupDocument}
                      onSaveWorking={onSaveWorking}
                      saveAsWorkingDraftResponse={saveAsWorkingDraftResponse}
                      discard={discard}
                      onCancel={onCancel}
                    />
                  )}
                </span>
              ) : (
                <span>
                  {!isEditingGroupCreatedFileDocument && (
                    <PermissionsComponent hasPermission={isNotReadOnlyInCurrentPractice} >
                      <button
                        className="btn btn-primary mr-1-2"
                        disabled={locked}
                        onClick={(isSaveCopy && !isCopied) ? () => onSaveCopy() : startEditing}
                      >
                        {(isSaveCopy && !isCopied) ? 'Create new copy' : 'Edit'}
                      </button>
                    </PermissionsComponent>
                  )}
                  {userCurrentlyEditing && locked &&
                    <span>{MaybeDisplayDocumentTakeOver(
                      null, userCurrentlyEditing, isAdmin, null, "underlined", templateDocumentVersion, openDocumentTakeOverModal
                      )}
                    </span>
                  }
                  {isGroupAdmin && group_template_document && !editingGroupDocument &&
                    <Link
                      templateVersion={doc}
                      className="btn btn-default ml-1"
                      to={editGroupDocumentLink}
                    >
                      Edit Group Version
                    </Link>
                  }
                  {(isAdopted || isCopied) && (
                    <UpdateToIfLaterVersion
                      adoptedDocument={adoptedDocument}
                      copiedDocument={copiedDocument}
                      documentKind={templateDocumentKind}
                    />
                  )}
                </span>
              )
            )}
            {hasAccessToDocumentReviews && adoptedDocument && !isGroupsAppOrGroupDocuments && !isEditing &&
              <button
                type="button"
                className="btn btn-default"
                onClick={openDocumentReviewModal}
              >
                {getDocumentReviewTitle(documentReview)}
              </button>
            }
            <div className="pull-right">
              {canSendDocReadToTeam && !isEditing && hasDocumentsToReadAccess &&
                <button
                  type="button"
                  className="btn btn-default"
                  disabled={Boolean(templateDocumentVersionReadRequests)}
                  onClick={() => this.props.openDocReadAddTeamMembersModal(
                    templateDocumentVersion.get('id'),
                    currentPracticeId
                  )}
                >
                  {
                    templateDocumentVersionReadRequests ?
                      `${templateDocumentVersionReadRequests.get('number_of_read')}/${templateDocumentVersionReadRequests.get('number_of_requests')} READ`
                      :'Send to team'
                  }
                </button>
              }

              <DownloadDocumentButton
                documentId={templateDocumentVersion.get('id')}
                documentType={DOCUMENT_TYPE.TEMPLATE}
                pristine={pristine}
                onSave={(documentType, isGenerate) => onSave(documentType, isGenerate, true)}
                onSaveCopy={(documentType) => onSaveCopy(documentType)}
                canSave={canSave}
                isSaveCopy={isSaveCopy}
                downloadPDFLink={getDocumentExportDownLoadURL(templateDocumentVersion)}
              />
            </div>
            {hasSucceeded(sendDocReadToTeamResponse) && (
              <p className="alert mt-1 alert-success">Successfully sent read request to team.</p>
            )}
          </div>
        </div>

        {(isEditing && !readOnly) ? (
          <Editor
            editorContent={editorContent}
            content={(!locked && working_version) ? working_version.get('content') : content}
            content_file={content_file}
            content_file_name={content_file_name}
            isLandscape={isLandscape}
            setEditorContent={setEditorContent}
            resetSaveResponses={resetSaveResponses}
            isEditingGroupCreatedDocument={isEditingGroupCreatedDocument}
            isEditingGroupCreatedFileDocument={isEditingGroupCreatedFileDocument}
            updateGroupTemplateDocumentFileVersion={updateGroupTemplateDocumentFileVersion}
            groupTemplateDocumentNewFileVersionResponse={groupTemplateDocumentNewFileVersionResponse}
            groupTemplateDocumentCurrentFileVersionResponse={groupTemplateDocumentCurrentFileVersionResponse}
          />
        ) : (
          <Preview
            content={content}
            header={header}
            footer={footer}
            content_file={content_file}
            content_file_name={content_file_name}
            version={version_number}
            name={name}
            isLandscape={isLandscape}
            isEditingGroupCreatedFileDocument={isEditingGroupCreatedFileDocument}
            startEditingGroupCreatedFileDocument={startEditingGroupCreatedFileDocument}
          />
        )}

        {isPracticeAdmin && isEditing && isAdopted &&
          <div className="pb-1">
            <button
              className="btn btn-warning"
              disabled={locked}
              onClick={restoreAdoptedVersion}
            >
              Restore
            </button>
          </div>
        }

        <div className="row pb-1">
          <div className="col-1">
            <TemplateDocumentTerms year={moment().year()} />
          </div>
        </div>
      </DocumentEditingMayDisallow>
    );
  }
}

export default DocumentEditor;
