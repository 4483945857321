import React from 'react';

import { CYCLE_LABELS_MAPPED_TO_INDEX } from '^/components/app/groups/insights/constants';
import withFilters from '^/components/app/groups/insights/hoc/withFilters';
import PureComponent from '^/components/common/PureComponent';
import { capitalize } from '^/utils';

export function getTimePeriodFilters(insightConfigFilters, newValue, storeFilters, storedFilters) {
  if (newValue === '') {
    // Switch back to date range filtering
    return insightConfigFilters.set(
      'date_from', storedFilters.dateFrom
    ).set(
      'date_to', storedFilters.dateTo
    ).delete('cycle_index');
  }
  // Store date range filters and switch to cycle index filtering
  storeFilters({
    dateFrom: insightConfigFilters.get('date_from') || storedFilters.dateFrom,
    dateTo: insightConfigFilters.get('date_to') || storedFilters.dateTo
  })
  return insightConfigFilters.delete('date_from').delete('date_to').set('cycle_index', newValue);
}

export class TimePeriodFilter extends PureComponent {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    this.props.onChange(event.target.value);
  }

  render() {
    const { insightsConfigWithFilters, title } = this.props;
    const selectedCycleIndex = insightsConfigWithFilters.getIn([0, 'filters', 'cycle_index']);
    const dateOptionLabel = this.props.dateOptionLabel || 'Selected dates';

    return (
      <h4 className="mb-1 mt-1">{title || 'View by period:'}
        <select
          className="insights-dropdown"
          onChange={this.onChange}
        >
          <option value="">{dateOptionLabel}</option>
          <option value="0">Current cycle</option>
          <option value="1">Previous cycle</option>
        </select>
        <span className="print-dropdown-as-text">
          {selectedCycleIndex ? capitalize(CYCLE_LABELS_MAPPED_TO_INDEX[selectedCycleIndex]) : dateOptionLabel}
        </span>
      </h4>
    );
  }
}

export default withFilters(TimePeriodFilter);
