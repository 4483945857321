export const CHART_HEIGHT = '125px';
export const CHART_ID = 'ACTIVITIES_CHART';
export const CHART_TYPE = 'PieChart';
export const CHART_CONFIG = {
  pieHole: 0.5,
  colors: ['#7ED321', '#F5CD00', '#EF5A21'],
  chartArea: {
    width: '100%',
    height: '100%',
    top: 10,
    bottom: 10,
  },
  legend: {
    position: 'labeled',
    labeledValueText: 'percentage',
    textStyle: {
      fontSize: 15,
      color: '#17384B'
    }
  },
  pieSliceText: 'none',
  tooltip: {
    trigger:'none'
  }
};

export const EMPTY_DATA = [
  ['Status', 'Percentage'],
  ['Completed', 0],
  ['In-progress', 0],
  ['Outstanding', 0],
];
