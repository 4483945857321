import React from 'react';
import moment from 'moment';
import ImmutablePropTypes from 'react-immutable-proptypes';
import DatePicker from 'react-datepicker';

import withFilters from '^/components/app/groups/insights/hoc/withFilters';
import PureComponent from '^/components/common/PureComponent';
import { formatBackendDate, FORMAT_DATE } from '^/utils';


export const DatePickerInput = ({ value, onClick, placeholder, onClear, className }) => {
  return (
    <span className="insights-date-filter">
      <span className={className} onClick={onClick}>
        {value || placeholder}
      </span>
      { value && onClear &&
        <span className="clear-filter" onClick={onClear}>
          Clear
        </span>
      }
    </span>
  );
};


export class DateFilter extends PureComponent {
  constructor(props) {
    super(props);
    this.getValue = this.getValue.bind(this);
    this.onClear = this.onClear.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  getValue() {
    const { selectedValue } = this.props;
    return selectedValue && moment(selectedValue).isValid() ? moment(selectedValue) : null;
  }

  onClear() {
    this.props.onChange();
  }

  onChange(date) {
    this.props.onChange(formatBackendDate(date));
  }

  render() {
    const { className, isClearable, placeholderText, minDate, maxDate } = this.props;

    return (
      <DatePicker
        fixedHeight
        showYearDropdown
        dateFormat={FORMAT_DATE}
        selected={this.getValue()}
        onChange={this.onChange}
        minDate={minDate && moment(minDate)}
        maxDate={moment(maxDate)}
        placeholderText={placeholderText}
        className={className}
        customInput={<DatePickerInput onClear={isClearable ? this.onClear : null} />}
      />
    );
  }
}

DateFilter.propTypes = {
  filterKey: React.PropTypes.string.isRequired,
  insightsConfig: ImmutablePropTypes.list.isRequired,
  selectedValue: React.PropTypes.string,
  className: React.PropTypes.string,
  placeholderText: React.PropTypes.string,
  isClearable: React.PropTypes.bool,
  minDate: React.PropTypes.string,
  maxDate: React.PropTypes.string,
};

export default withFilters(DateFilter);
