import React from 'react';
import { connect } from 'react-redux';

import PureComponent from '^/components/common/PureComponent';
import Loading from '^/components/app/content/Loading';
import {
  landingInsightsConfig,
  FILTER_KEYS,
  INSIGHTS,
} from '^/components/app/groups/insights/constants';
import Header from '^/components/app/groups/insights/Header';
import { hasAccessToAdoptedGroupDocuments } from '^/stateHelpers';
import ActiveUserCount from '^/components/app/groups/insights/active-user-count/ActiveUserCountLanding';
import ActivitiesLanding from '^/components/app/groups/insights/activities/ActivitiesLanding';
import GroupDocumentsLanding from '^/components/app/groups/insights/group-documents/GroupDocumentsLanding';
import GroupDocumentsDeniedAccessLanding from '^/components/app/groups/insights/GroupDocumentsDeniedAccessLanding';
import PrintButton from '^/components/app/groups/insights/visualisations/PrintButton';
import SubGroupFilter from '^/components/app/groups/insights/filters/SubGroupFilter';
import ContentTabset from '^/components/app/content/ContentTabset';
import ContentTab from '^/components/app/content/ContentTab';
import ComplianceActivitiesCompletedLanding from '^/components/app/groups/insights/compliance-activities/ComplianceActivitiesCompletedLanding';
import TimePeriodFilter, {
  getTimePeriodFilters,
} from '^/components/app/groups/insights/filters/TimePeriodFilter';
import withLoadLastUpdatedTimestampOnMount from '^/components/app/groups/insights/hoc/withLoadLastUpdatedTimestampOnMount';
import { getCollection } from '^/actions/collections';
import { PRACTICE_GROUP_SYSTEMS } from '^/consts/collectionKeys';
import { isPending } from '^/consts/responseStates';

const INSIGHTS_STATS = 'INSIGHTS_STATS';

export class InsightsPage extends PureComponent {
  componentDidMount() {
    if (!this.props.practiceGroupSystems) {
      this.props.getCollection(PRACTICE_GROUP_SYSTEMS);
    }
  }

  render() {
    const {
      practiceGroupSystems,
      practiceGroupSystemsResponse,
      hasAdoptedGroupDocumentAccess,
      tabsetName,
      location: { pathname, query },
    } = this.props;

    if (isPending(practiceGroupSystemsResponse) || !practiceGroupSystems) {
      return <Loading />;
    }

    return (
      <div className="col-1">
        <Header query={query} />
        <div className="display-flex">
          <div>
            <TimePeriodFilter
              insightsConfig={landingInsightsConfig}
              getFilters={getTimePeriodFilters}
              title="View by period (activities):"
              dateOptionLabel="Last 12 months"
              onChangeInsights={[
                INSIGHTS.ACTIVITY_COMPLETION_RATE_LANDING,
                INSIGHTS.COMPLIANCE_ACTIVITIES_COMPLETED_LANDING,
              ]}
            />
          </div>
          <div className="ml-2">
            <SubGroupFilter
              insightsConfig={landingInsightsConfig}
              filterKey={FILTER_KEYS.SUB_GROUP}
              pathname={pathname}
              selectedSubGroup={query.sub_group}
              country={query.country}
            />
          </div>
        </div>
        <div className="row display-flex flex-wrap">
          <div className="col-1-3 col-md-1-1 col-1-1 mb-2 print-full-width">
            <ActivitiesLanding query={query} />
          </div>

          <div className="col-1-3 col-md-1-2 col-1-1 mb-2 print-half-width">
            <ActiveUserCount query={query} />
          </div>
          <div className="col-1-3 col-md-1-2 col-1-1 mb-2 print-half-width">
            {hasAdoptedGroupDocumentAccess ? (
              <GroupDocumentsLanding query={query} />
            ) : (
              <GroupDocumentsDeniedAccessLanding query={query} />
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-1 mb-1">
            <ContentTabset
              name={tabsetName}
              defaultActiveTab={0}
              className="mb-1"
            >
              {practiceGroupSystems
                .map((groupCountry, idx) => {
                  return (
                    <ContentTab
                      key={groupCountry.get('country')}
                      tabsetName={tabsetName}
                      tabId={idx}
                      tabName={groupCountry.get('name')}
                    >
                      <ComplianceActivitiesCompletedLanding
                        query={query}
                        pathname={pathname}
                        complianceSystemName={groupCountry.get('name')}
                        country={groupCountry.get('country')}
                        labels={groupCountry.get('group_insights_headings')}
                      />
                    </ContentTab>
                  );
                })
                .toArray()}
            </ContentTabset>
          </div>
        </div>

        <div className="pull-right mb-2">
          <PrintButton />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    hasAdoptedGroupDocumentAccess: hasAccessToAdoptedGroupDocuments(state),
    tabsetName: INSIGHTS_STATS,
    practiceGroupSystems: state.collections.getIn([
      PRACTICE_GROUP_SYSTEMS,
      'items',
    ]),
    practiceGroupSystemsResponse: state.responses.getIn([
      'getCollection',
      PRACTICE_GROUP_SYSTEMS,
    ]),
  };
}

export default connect(mapStateToProps, {
  getCollection,
})(withLoadLastUpdatedTimestampOnMount(InsightsPage));
