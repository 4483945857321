import React from 'react';
import { Link } from 'react-router';
import UserCreatedMarkup from '^/components/common/content/UserCreatedMarkup';
import { formatDate } from '^/utils';


const NewsDetail = ({newsStory, newerNewsStory, olderNewsStory}) => {
  const { title, created, content, image } = newsStory.toObject();

  return (
    <div>
      <div className="row">
        <p className="breadcrumb news-image">NEWS</p>

        <div className="news-wrapper mb-1">
          <h1>{title}</h1>

          <p className="lighten-text">
            {formatDate(created)}
          </p>
        </div>
      </div>

      <div className="row">
        {image && (
          <img className="news-image" src={image} />
        )}

        <div className="mb-1 news-wrapper">
          <div className="news-content">
            <UserCreatedMarkup text={content} />
          </div>

          <div className="row mt-1">
            {newerNewsStory && (
              <Link className="btn btn-default pull-left" to={`/page/news/${newerNewsStory.get('id')}/`}>
                &lt; Newer
              </Link>
            )}
            {olderNewsStory && (
              <Link className="btn btn-default btn-right-margin pull-right" to={`/page/news/${olderNewsStory.get('id')}/`}>
                Older &gt;
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsDetail;
