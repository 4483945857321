import React from 'react';
import { Link } from 'react-router';
import { Map, List } from 'immutable';
import { connect } from 'react-redux';

import { getPracticeMembers } from '^/stateHelpers';
import { DIGITAL_RECORDS_LIST } from '^/consts/collectionKeys';
import { RECORD_FIELDS } from '^/components/app/digital-tools/records/digital-records/recordFields';
import { RECORD_TYPE_NAME, getAddItemButtonText } from '^/components/app/digital-tools/records/digital-records/recordTypes';

import PureComponent from '^/components/common/PureComponent';
import TableList from '^/components/app/tables/TableList';
import CSVExporterButton from '^/components/common/CSVExporterButton';
import { recordTypeUrlToRecordType } from '^/utils';

export const PAGE_SIZE = 20;

export class RecordsListPage extends PureComponent {
  
  render() {
    const { currentPractice, recordTypeUrl, recordType, practiceMembers } = this.props

    const responsibleStaffMembers = practiceMembers ? practiceMembers.map(document => {
      return {
        select_name: document.getIn(['full_name']),
        select_value: document.getIn(['id']),
      };
    })
    .toJS() : [];

    const RECORD_FILTER_OPTIONS = {
      EVENT: {
        'Status': [
          { select_name: 'Open', select_value: 'OPEN' },
          { select_name: 'In Progress', select_value: 'IN_PROGRESS' },
          { select_name: 'Closed', select_value: 'CLOSED' },
        ],
        'Type of Event': [
          { select_value: 'SIGNIFICANT', select_name: 'Significant' },
          {
            select_value: 'HAZARD_OBSERVATION',
            select_name: 'Hazard Observation',
          },
          { select_value: 'OTHER', select_name: 'Other' },
          { select_value: 'PROBLEM', select_name: 'Problem' },
          {
            select_value: 'SAFETY_INCIDENT',
            select_name: 'Safety Incident',
          },
          { select_value: 'NEVER_EVENT', select_name: 'Never Event' },
          { select_value: 'COMPLIMENT', select_name: 'Compliment' },
          { select_value: 'COMPLAINT', select_name: 'Complaint' },
        ],
        'Date of Event': [
          {select_value: 'SAME_DAY', select_name: 'In the last day'},
          {select_value: 'LAST_WEEK', select_name: 'In the last week'},
          {select_value: 'LAST_MONTH', select_name: 'In the last month'},
          {select_value: 'LAST_YEAR', select_name: 'In the last year'},
        ],
        'Lead Team Member': responsibleStaffMembers
      },
      COSH: {},
      FIRE_DRILL: {},
      EMERGENCY_DRUGS_AND_EQUIPMENT: {},
    };

    return (
      <div>
        <div className="pull-right">
          <div>
            <Link
              to={`/page/tools/records/${recordTypeUrl}/create/`}
              className="btn btn-primary fullwidth"
            >
              + {getAddItemButtonText(recordType)}
            </Link>
          </div>
          <div>
            <CSVExporterButton
              url={`/digital-records/csv-export/${recordType}/`}
              className="btn btn-default mt-1 fullwidth"
            >
              DOWNLOAD
            </CSVExporterButton>
          </div>
        </div>
        <TableList
          model={DIGITAL_RECORDS_LIST}
          fields={RECORD_FIELDS[recordType]}
          pageSize={PAGE_SIZE}
          title={`${currentPractice.get('name')}: ${RECORD_TYPE_NAME[recordType]}`}
          defaultFilters={Map({type: recordType})}
          recordType = {recordType}
          filterOptions = {RECORD_FILTER_OPTIONS}
          noSearch
        />
      </div>
    );
  }
};

export function mapStateToProps(state, props) {
  const recordTypeUrl = props.params.recordTypeUrl
  const recordType = recordTypeUrlToRecordType(recordTypeUrl);
  return {
    currentPractice: state.currentPractice,
    recordTypeUrl,
    recordType,
    practiceMembers: getPracticeMembers(state) || List(),
  };
}
export default connect(mapStateToProps, null) (RecordsListPage);
