import React from 'react';
import { connect } from 'react-redux';
import { List } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { closeModal } from '^/actions/modals';
import { sendDocReadToTeamAndCloseModal, addReadRequestsToDocReadAndCloseModal } from '^/actions/actionSequences';
import { ALL_PRACTICE_MEMBERS, DOCUMENT_VERSIONS_TO_READ } from '^/consts/collectionKeys';
import { isPending } from '^/consts/responseStates';
import { getAllPracticeMembers } from '^/actions/collections';
import { removeMembersAlreadyAdded } from '^/utils';
import { getActiveMembers } from '^/models/practice';

import DocReadAddTeamMembersForm from '^/components/app/template-documents/DocReadAddTeamMembersForm';
import Loading from '^/components/app/content/Loading';
import PureComponent from '^/components/common/PureComponent';

export class DocReadAddTeamMembersModal extends PureComponent {
  componentDidMount() {
    const { practiceId } = this.props;
    this.loadPracticeMembers(practiceId);
  }

  loadPracticeMembers(practiceId) {
    if (practiceId) {
      this.props.getAllPracticeMembers(practiceId);
    }
  }

  sendDocToTeam(data) {
    const { practiceId, templateDocumentVersionId, documentVersionToReadId } = this.props;

    if (documentVersionToReadId) {
      this.props.addReadRequestsToDocReadAndCloseModal(
        documentVersionToReadId,
        {
          ...data,
          practice_id: practiceId,
        }
      );
    } else {
      this.props.sendDocReadToTeamAndCloseModal(
          {
            ...data,
            practice_id: practiceId,
            template_document_version_id: templateDocumentVersionId,
          }
        );
    }
  }

  render() {
    const {
      onClose,
      allActivePracticeMembers,
      allPracticeMembersResponse,
      documentVersionReadRequests,
      alreadyAddedMembers,
    } = this.props;

    if (isPending(allPracticeMembersResponse)) {
      return <Loading />;
    }

    return (
      <div>
        <DocReadAddTeamMembersForm
          onSubmit={(data) => this.sendDocToTeam(data)}
          onClose={onClose}
          allActivePracticeMembers={alreadyAddedMembers ?
            removeMembersAlreadyAdded(documentVersionReadRequests, allActivePracticeMembers) :
            allActivePracticeMembers
          }
        />
      </div>
    )
  };
};

DocReadAddTeamMembersModal.propTypes = {
  allActivePracticeMembers: ImmutablePropTypes.list,
  practiceId: React.PropTypes.string,
  templateDocumentVersionId: React.PropTypes.string,
  documentVersionToReadId: React.PropTypes.string,
  sendDocReadToTeamAndCloseModal: React.PropTypes.func,
  addReadRequestsToDocReadAndCloseModal: React.PropTypes.func,
  alreadyAddedMembers: React.PropTypes.bool,
  onClose: React.PropTypes.func,
  documentVersionReadRequests: ImmutablePropTypes.list,
};

export function mapStateToProps(state, props) {
  const practiceMembers = state.collections.getIn(
    [ALL_PRACTICE_MEMBERS, 'items'],
    List()
  );
  return {
    allActivePracticeMembers: getActiveMembers(practiceMembers),
    allPracticeMembersResponse: state.responses.getIn(
      ['getCollection', ALL_PRACTICE_MEMBERS]
    ),
    documentVersionReadRequests: state.collections.getIn([
      `${DOCUMENT_VERSIONS_TO_READ}/${props.documentVersionToReadId}/document-read-requests`,
      'items'
    ],
      List()
    )
  }
}

export default connect(
  mapStateToProps,
  {
    getAllPracticeMembers,
    sendDocReadToTeamAndCloseModal,
    addReadRequestsToDocReadAndCloseModal,
    onClose: closeModal
  }
)(DocReadAddTeamMembersModal);
