import React from 'react';
import { connect } from 'react-redux';

import PureComponent from '^/components/common/PureComponent';
import {
  complianceActivitiesInsightsConfig,
  FILTER_KEYS,
} from '^/components/app/groups/insights/constants';
import Header from '^/components/app/groups/insights/Header';
import ComplianceActivitiesTable from '^/components/app/groups/insights/compliance-activities/ComplianceActivitiesTable';
import ComplianceActivitiesCompletedDetails from '^/components/app/groups/insights/compliance-activities/ComplianceActivitiesCompletedDetails';
import SubGroupFilter from '^/components/app/groups/insights/filters/SubGroupFilter';
import TimePeriodFilter, { getTimePeriodFilters } from '^/components/app/groups/insights/filters/TimePeriodFilter';
import { getCollection } from '^/actions/collections';
import { PRACTICE_GROUP_SYSTEMS } from '^/consts/collectionKeys';
import { isPending } from '^/consts/responseStates';
import Loading from '^/components/app/content/Loading';

export class ComplianceActivitiesPage extends PureComponent {
  componentDidMount() {
    if (!this.props.practiceGroupSystems) {
      this.props.getCollection(PRACTICE_GROUP_SYSTEMS);
    }
  }

  render() {
    const {
      location: { pathname, query },
      practiceGroupSystems,
      practiceGroupSystemsResponse,
    } = this.props;
    const { sub_group, country } = query;

    if (isPending(practiceGroupSystemsResponse) || !practiceGroupSystems) {
      return <Loading />;
    }

    const selectedSystemData = practiceGroupSystems.find(
      item => item.get('country') === country
    );

    return (
      <div className="col-1">
        <Header
          shouldLinkToDashboard
          subtitle={`Compliance Reports - ${selectedSystemData.get(
            'country_label'
          )}`}
          query={query}
        />
        <div className="display-flex">
          <div>
            <TimePeriodFilter
              insightsConfig={complianceActivitiesInsightsConfig}
              getFilters={getTimePeriodFilters}
            />
          </div>
          <div className="ml-2">
            <SubGroupFilter
              insightsConfig={complianceActivitiesInsightsConfig}
              filterKey={FILTER_KEYS.SUB_GROUP}
              pathname={pathname}
              selectedSubGroup={sub_group}
              country={country}
            />
          </div>
        </div>
        <ComplianceActivitiesCompletedDetails
          pathname={pathname}
          selectedSubGroup={sub_group}
          complianceSystemName={selectedSystemData.get('name')}
          country={selectedSystemData.get('country')}
          labels={selectedSystemData.get('group_insights_headings')}
        />
        <ComplianceActivitiesTable
          country={country}
          labels={selectedSystemData.get('group_insights_headings')}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    practiceGroupSystems: state.collections.getIn([
      PRACTICE_GROUP_SYSTEMS,
      'items',
    ]),
    practiceGroupSystemsResponse: state.responses.getIn([
      'getCollection',
      PRACTICE_GROUP_SYSTEMS,
    ]),
  };
}

export default connect(mapStateToProps, {
  getCollection,
})(ComplianceActivitiesPage);
