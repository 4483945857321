import { Map, fromJS } from 'immutable';
import _ from 'underscore';
import moment from 'moment';
import composeReducers from '^/reducers/composeReducers';
import combineImmutableReducers from '^/combineImmutableReducers';
import { CLOSE_MODAL } from '^/actions/modals';
import * as actions from '^/actions/actions';
import * as groupDocuments from '^/actions/groupDocuments';
import * as insights from '^/actions/insights';
import * as collections from '^/actions/collections';
import * as items from '^/actions/items';
import * as stats from '^/actions/stats';
import * as dashboardActivitiesAndUpdatedTemplates from '^/actions/dashboardActivitiesAndUpdatedTemplates';
import * as responseStates from '^/consts/responseStates';


function makeResponseReducer(actionSet, keyed, responseName) {
  return (state = Map(), action) => {
    const time = moment();
    const meta = fromJS(action.meta);
    const source = (action.meta && action.meta.source) || 'default';
    switch (action.type) {
      case actionSet.REQUEST:
        const pendingResponse = Map({ meta, state: responseStates.PENDING, time });
        return keyed ? state.set(source, pendingResponse) : pendingResponse;
      case actionSet.SUCCESS:
        const successResponse = Map({ meta, state: responseStates.SUCCESSFUL, payload: fromJS(action.payload), time });
        return keyed ? state.set(source, successResponse) : successResponse;
      case actionSet.FAILURE:
        const failureResponse = Map({
          meta,
          state: responseStates.FAILED,
          errors: action.error ? fromJS(action.payload.response) : null,
          time,
        });
        return keyed ? state.set(source, failureResponse) : failureResponse;
      case actions.RESET_RESPONSE_ERROR_FOR_KEY:
        const { responseKey, key } = action.payload;
        if (responseName === responseKey) {
          return keyed ? state.setIn([source, 'errors', key]) : state.setIn(['errors', key]);
        }
        return state;
      default:
        return state;
    }
  };
}

function makeResponsesReducer(actionsByKey) {
  return combineImmutableReducers(
    _.mapObject(actionsByKey,
      (actionType, responseName) =>
        actionType.actionSet instanceof Array
          ? composeReducers(actionType.actionSet.map(makeResponseReducer), Map())
          : makeResponseReducer(actionType.actionSet, actionType.keyed, responseName)
    )
  );
}

const responsesReducer = makeResponsesReducer({
  changePassword: {actionSet: actions.CHANGE_PASSWORD},
  register: {actionSet: actions.REGISTER},
  login: {actionSet: actions.LOGIN},
  getUserInfo: {actionSet: actions.GET_USER_INFO},
  switchPractice: {actionSet: actions.SWITCH_PRACTICE},
  exportTableOptions: {actionSet: actions.GET_EXPORT_TABLES},
  exportTableUrl: {actionSet: actions.GET_EXPORT_URL},
  setUserLock: {actionSet: actions.SET_USER_LOCK},
  loadDashboardActivitiesAndUpdatedTemplates: {actionSet: dashboardActivitiesAndUpdatedTemplates.LOAD_DASHBOARD_ACTIVITY_AND_UPDATED_TEMPLATES},
  setupPersonalDetails: {actionSet: actions.SETUP_PERSONAL_DETAILS},
  setupPracticeDetails: {actionSet: actions.SETUP_PRACTICE_DETAILS},
  setupTeamMembers: {actionSet: actions.SETUP_TEAM_MEMBERS},
  getSetupTeamMembers: {actionSet: actions.GET_SETUP_TEAM_MEMBERS},
  setupCycleResponsibilities: {actionSet: actions.SETUP_CYCLE_RESPONSIBILITIES},
  setupCycleReminders: {actionSet: actions.SETUP_CYCLE_REMINDERS},
  setupCycleGroupTasks: {actionSet: actions.SETUP_CYCLE_GROUP_TASKS},
  setupComplianceReviews: {actionSet: actions.SETUP_COMPLIANCE_REVIEWS},
  setupSecurityAudits: {actionSet: actions.SETUP_SECURITY_AUDITS},
  setupAssessments: {actionSet: actions.SETUP_ASSESSMENTS},
  setupMeetings: {actionSet: actions.SETUP_MEETINGS},
  loadTemplateActivities: {actionSet: actions.LOAD_TEMPLATE_ACTIVITIES},
  createTemplateVersion: {actionSet: items.CREATE_TEMPLATE_VERSION},
  sendDocReadToTeamResponse: {actionSet: items.SEND_DOCUMENT_READ_TO_TEAM},
  initiateTransaction: {actionSet: actions.INITIATE_TRANSACTION},
  setupNewCycle: {actionSet: actions.SETUP_NEW_CYCLE},
  setupCycleComplianceDetails: {actionSet: actions.SETUP_CYCLE_COMPLIANCE_DETAILS},
  reassignMember: {actionSet: actions.REASSIGN_MEMBER},
  resetDemo: {actionSet: actions.RESET_DEMO},
  upgradeDemo: {actionSet: actions.UPGRADE_DEMO},
  setupCycleReviewTasks: {actionSet: actions.SETUP_CYCLE_REVIEW_TASKS},
  setupCycleReviewGroupTasks: {actionSet: actions.SETUP_CYCLE_REVIEW_GROUP_TASKS},
  setupCycleComplianceReviews: {actionSet: actions.SETUP_CYCLE_COMPLIANCE_REVIEWS},
  getLatestTerms: {actionSet: actions.GET_LATEST_TERMS},
  uploadActivityTaskAssigneeIdFile: {actionSet: actions.UPLOAD_ACTIVITY_TASK_ASSIGNEE_FILE},
  removeUploadedFile: {actionSet: actions.REMOVE_UPLOADED_FILE},
  togglePrivacyUploadedFile: {actionSet: actions.TOGGLE_PRIVACY_UPLOADED_FILE},
  generateComplianceReportPdf: {actionSet: actions.GENERATE_COMPLIANCE_REPORT_PDF},
  saveCycleResponsibilities: {actionSet: actions.SAVE_CYCLE_RESPONSIBILITIES},
  applyTemplateActivityToLiveCycle: {actionSet: actions.APPLY_TEMPLATE_ACTIVITY_TO_LIVE_CYCLES},
  checkTemplateActivityLiveApplicationProgress: {actionSet: actions.CHECK_LIVE_TEMPLATE_ACTIVITY_APPLICATION_PROGRESS},
  adoptTemplateVersion: {actionSet: items.ADOPT_TEMPLATE_VERSION },
  copyTemplateVersion: {actionSet: items.COPY_TEMPLATE_VERSION },
  rebaseCopiedTemplate: {actionSet: items.REBASE_COPIED_TEMPLATE },
  getReportURL: {actionSet: actions.GET_REPORT_URL},
  getCSVReportURL: {actionSet: actions.GET_CSV_REPORT_URL},
  getHistoryReportURL: {actionSet: actions.GET_HISTORY_REPORT_URL},
  autosaveWorkingVersion: {actionSet: actions.AUTOSAVE_WORKING_VERSION},
  reassignPrivateDocuments: {actionSet: actions.REASSIGN_PRIVATE_DOCUMENTS},
  resendInvite: {actionSet: actions.RESEND_INVITE},
  removeInvite: {actionSet: actions.REMOVE_INVITE, keyed: true},
  removeSSOInvite: {actionSet: actions.REMOVE_SSO_INVITE, keyed: true},
  exportDocumentFolder: {actionSet: actions.EXPORT_DOCUMENT_FOLDER},
  pollExportStatusBulk: {actionSet: actions.POLL_EXPORT_STATUS_BULK},
  mergeDocuments: {actionSet: actions.MERGE_DOCUMENTS},
  uploadFiletoSignedUrl: {actionSet: actions.UPLOAD_FILE_TO_SIGNED_URL},
  completeFileUpload: {actionSet: actions.COMPLETE_FILE_UPLOAD},
  getUploadForm: {actionSet: actions.GET_UPLOAD_FORM},
  saveAsWorkingDraft: {actionSet: items.SAVE_AS_WORKING_DRAFT},
  changeCollaborators: {actionSet: actions.UPDATE_COLLABORATORS},
  changeFileFolder: {actionSet: actions.CHANGE_FILE_FOLDER},
  restorePolicyAndOverviewDocuments: {actionSet: actions.RESTORE_POLICY_AND_OVERVIEW_DOCUMENTS},
  documentReadRequestSendReminderResponse: {actionSet: actions.DOCUMENT_READ_REQUEST_SEND_REMINDER, keyed: true},
  documentVersionToReadSendReminderResponse: {actionSet: actions.DOCUMENT_VERSION_TO_READ_SEND_REMINDER, keyed: true},

  getCollection: {actionSet: collections.GET_COLLECTION, keyed: true},
  addToCollection: {actionSet: collections.ADD_TO_COLLECTION, keyed: true},
  loadItem: {actionSet: items.LOAD_ITEM, keyed: true},
  updateItem: {actionSet: items.UPDATE_ITEM, keyed: true},
  createItem: {actionSet: items.CREATE_ITEM, keyed: true},
  getItemOptions: {actionSet: items.GET_ITEM_OPTIONS, keyed: true},
  deleteItem: {actionSet: collections.DELETE_FROM_COLLECTION, keyed: true},
  loadStats: {actionSet: stats.LOAD_STATS, keyed: true},
  renderDocument: {actionSet: actions.RENDER_DOCUMENT, keyed: true},
  renderDocumentVersionToRead: {actionSet: actions.RENDER_DOCUMENT_VERSION_TO_READ, keyed: true},
  renderQMRReport: {actionSet: actions.RENDER_QMR_REPORT, keyed: true},
  renderAdminDocumentVersion: {actionSet: actions.RENDER_ADMIN_DOCUMENT, keyed: true},
  pollExport: {actionSet: actions.POLL_EXPORT, keyed: true},
  pollUpdateGroupTask: {actionSet: actions.POLL_UPDATE_GROUP_TASK, keyed: true},
  pollBulkDocSendReadRequest: {actionSet: actions.POLL_BULK_SEND_READ_REQUEST, keyed: true},
  markTaskAssigneeComplete: {actionSet: actions.MARK_TASK_ASSIGNEE_COMPLETE, keyed: true},
  loadTemplateLatestVersion: {actionSet: items.LOAD_TEMPLATE_LATEST_VERSION, keyed: true},
  loadTemplateLatestGroupVersion: {actionSet: items.LOAD_TEMPLATE_LATEST_GROUP_VERSION, keyed: true},

  loadInsightData: {actionSet: insights.LOAD_INSIGHT_DATA, keyed: true},
  loadLastDatabaseSync: {actionSet: insights.LOAD_LAST_DATABASE_SYNC},

  updateGroupTemplateDocumentFileVersion: {
    actionSet: groupDocuments.UPDATE_GROUP_TEMPLATE_DOCUMENT_FILE_VERSION,
    keyed: true
  },

  updateDigitalRecordResponse: {actionSet: actions.UPDATE_DIGITAL_RECORDS, keyed: true},
  archiveDigitalRecordResponse: {actionSet: actions.ARCHIVE_DIGITAL_RECORDS, keyed: true},
  updateTemplateVersionFromWorkingDraft: {actionSet: actions.UPDATE_WORKING_DRAFT},
  getDigitalRecordChecklist: {actionSet: actions.GET_DIGITAL_RECORD_CHECKLIST},
  getDigitalRecordChecklistHistory: {actionSet: actions.GET_DIGITAL_RECORD_CHECKLIST_HISTORY},
  createUpdateDigitalRecordChecklist: {actionSet: actions.CREATE_UPDATE_DIGITAL_RECORD_CHECKLIST},
  getDigitalRecordNavigationList: {actionSet: actions.GET_DIGITAL_RECORD_NAVIGATION_LIST},
  getPredefinedChecklistFields: {actionSet: actions.GET_PREDEFINED_CHECKLIST_FIELDS},
  updateDynamicChecklistSnapshot: {actionSet: actions.UPDATE_DYNAMIC_CHECKLIST_SNAPSHOT},
  updateChecklistRelatedFiles: {actionSet: actions.UPDATE_CHECKLIST_RELATED_FILES},
  excludeTemplateActivitiesByGroup: {actionSet: actions.EXCLUDE_TEMPLATE_ACTIVITIES_BY_GROUP},
  excludeTemplateDocumentItem: {actionSet: groupDocuments.EXCLUDE_TEMPLATE_DOCUMENT_BY_GROUP},
  folderUploadedActivityTaskFilesCount: {actionSet: groupDocuments.GET_FOLDER_UPLOADED_ACTIVITY_TASK_FILES_COUNT},
  inheritGroupsFunctionality: {actionSet: actions.INHERIT_GROUPS_FUNCTIONALITY},
});

function responses(state = Map(), action) {
  const updatedState = responsesReducer(state, action);

  if (action.type === actions.RESET_RESPONSE) {
    return Map(updatedState).delete(action.payload);
  }
  else if (action.type === CLOSE_MODAL) {
    return Map();
  }
  else if (action.type === actions.SET_RESPONSE_ERROR_FOR_KEY) {
    return updatedState.setIn([action.responseKey, action.key, 'errors'], action.payload);
  }
  return updatedState;
}

export default responses;
