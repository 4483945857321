import { fromJS } from 'immutable';

export const PAGINATION_PAGE_SIZE = 10;

export const INSIGHTS = {
  ACTIVITY_COMPLETION_RATE: 'activityCompletionRate',
  ACTIVITY_COMPLETION_RATE_LANDING: 'activityCompletionRateLanding',
  ACTIVITY_COMPLETION_RATE_BY_PRACTICE: 'activityCompletionRateByPractice',
  ACTIVE_USER_COUNT: 'activeUserCount',
  ACTIVE_USER_COUNT_LANDING: 'activeUserCountLanding',
  ACTIVE_USER_COUNT_BY_PRACTICE: 'activeUserCountByPractice',
  GROUP_DOCUMENTS_ADOPTED: 'groupDocumentsAdopted',
  GROUP_DOCUMENTS_ADOPTED_LANDING: 'groupDocumentsAdoptedLanding',
  GROUP_DOCUMENTS_ADOPTED_BY_PRACTICE: 'groupDocumentsAdoptedByPractice',
  COMPLIANCE_ACTIVITIES_COMPLETED: 'complianceActivitiesCompleted',
  COMPLIANCE_ACTIVITIES_COMPLETED_LANDING:
    'complianceActivitiesCompletedLanding',
  COMPLIANCE_ACTIVITIES_COMPLETED_BY_PRACTICE:
    'complianceActivitiesCompletedByPractice',
};

export const INSIGHTS_API_URLS = {
  ACTIVITY_COMPLETION_RATE: '/group-insights/activity-completion-rates/',
  ACTIVITY_COMPLETION_RATE_BY_PRACTICE:
    '/group-insights/activity-completion-rates-by-practice/',
  ACTIVE_USER_COUNT: '/group-insights/active-user-count/',
  ACTIVE_USER_COUNT_BY_PRACTICE:
    '/group-insights/active-user-count-by-practice/',
  GROUP_DOCUMENTS_ADOPTED: '/group-insights/group-documents-adopted/',
  GROUP_DOCUMENTS_ADOPTED_BY_PRACTICE:
    '/group-insights/group-documents-adopted-by-practice/',
  COMPLIANCE_ACTIVITIES_COMPLETED:
    '/group-insights/compliance-activities-completed/',
  COMPLIANCE_ACTIVITIES_COMPLETED_BY_PRACTICE:
    '/group-insights/compliance-activities-completed-by-practice/',
};

export const INSIGHTS_CSV_API_URLS = {
  ACTIVE_USER_COUNT_BY_PRACTICE_REPORT:
    '/group-insights/active-user-count-by-practice-csv/',
  ACTIVITY_COMPLETION_RATE_BY_PRACTICE_REPORT:
    '/group-insights/activity-completion-rates-by-practice-csv/',
  COMPLIANCE_ACTIVITIES_COMPLETED_BY_PRACTICE_REPORT:
    '/group-insights/compliance-activities-completed-by-practice-csv/',
  GROUP_DOCUMENTS_ADOPTED_BY_PRACTICE_REPORT:
    '/group-insights/group-documents-adopted-by-practice-csv/',
};

export const PATHS = {
  GROUPS_DASHBOARD: '/page/groups/',
  ACTIVITIES: '/page/groups/insights/activities/',
  ACTIVE_USERS: '/page/groups/insights/active-users/',
  GROUP_DOCUMENTS: '/page/groups/insights/group-documents/',
  COMPLIANCE_ACTIVITIES: '/page/groups/insights/compliance-activities/',
};

export const landingInsightsConfig = fromJS([
  {
    insight: INSIGHTS.ACTIVITY_COMPLETION_RATE_LANDING,
    url: INSIGHTS_API_URLS.ACTIVITY_COMPLETION_RATE,
  },
  {
    insight: INSIGHTS.ACTIVE_USER_COUNT_LANDING,
    url: INSIGHTS_API_URLS.ACTIVE_USER_COUNT,
  },
  {
    insight: INSIGHTS.GROUP_DOCUMENTS_ADOPTED_LANDING,
    url: INSIGHTS_API_URLS.GROUP_DOCUMENTS_ADOPTED,
  },
  {
    insight: INSIGHTS.COMPLIANCE_ACTIVITIES_COMPLETED_LANDING,
    url: INSIGHTS_API_URLS.COMPLIANCE_ACTIVITIES_COMPLETED,
  },
]);

export const activitiesInsightsConfig = fromJS([
  {
    insight: INSIGHTS.ACTIVITY_COMPLETION_RATE,
    url: INSIGHTS_API_URLS.ACTIVITY_COMPLETION_RATE,
  },
  {
    insight: INSIGHTS.ACTIVITY_COMPLETION_RATE_BY_PRACTICE,
    url: INSIGHTS_API_URLS.ACTIVITY_COMPLETION_RATE_BY_PRACTICE,
  },
]);

export const activeUsersInsightsConfig = fromJS([
  {
    insight: INSIGHTS.ACTIVE_USER_COUNT,
    url: INSIGHTS_API_URLS.ACTIVE_USER_COUNT,
  },
  {
    insight: INSIGHTS.ACTIVE_USER_COUNT_BY_PRACTICE,
    url: INSIGHTS_API_URLS.ACTIVE_USER_COUNT_BY_PRACTICE,
  },
]);

export const groupDocumentsInsightsConfig = fromJS([
  {
    insight: INSIGHTS.GROUP_DOCUMENTS_ADOPTED,
    url: INSIGHTS_API_URLS.GROUP_DOCUMENTS_ADOPTED,
  },
  {
    insight: INSIGHTS.GROUP_DOCUMENTS_ADOPTED_BY_PRACTICE,
    url: INSIGHTS_API_URLS.GROUP_DOCUMENTS_ADOPTED_BY_PRACTICE,
  },
]);

export const complianceActivitiesInsightsConfig = fromJS([
  {
    insight: INSIGHTS.COMPLIANCE_ACTIVITIES_COMPLETED,
    url: INSIGHTS_API_URLS.COMPLIANCE_ACTIVITIES_COMPLETED,
  },
  {
    insight: INSIGHTS.COMPLIANCE_ACTIVITIES_COMPLETED_BY_PRACTICE,
    url: INSIGHTS_API_URLS.COMPLIANCE_ACTIVITIES_COMPLETED_BY_PRACTICE,
  },
]);

export const COMPLIANCE_ACTIVITIES_THRESHOLDS = {
  BAD: 0.75,
  OK: 0.9,
};

export const GROUP_DOCUMENTS_ADOPTED_THRESHOLDS = {
  BAD: 0.3,
  OK: 0.7,
  GOOD: 1,
};

export const FILTER_KEYS = {
  TYPE: 'type',
  DATE_FROM: 'date_from',
  DATE_TO: 'date_to',
  CYCLE_INDEX: 'cycle_index',
  SUB_GROUP: 'practice_sub_group_id',
  TEMPLATE_ACTIVITY: 'template_id',
};

export const LAST_DATABASE_SYNC_URL =
  '/group-insights/last-denormalised-table-sync/';

export const DATABASE_MODEL_NAME = {
  DOCUMENT: 'DenormalizedDocument',
  ACTIVITY: 'DenormalizedActivityorTask',
};

export const CYCLE_LABELS_MAPPED_TO_INDEX = {
  0: 'current cycle',
  1: 'previous cycle'
}
