import React from 'react';

import { hasStats } from '^/models/activities';
import LandingCard from '^/components/app/groups/insights/LandingCard';
import ActivitiesChart from '^/components/app/groups/insights/activities/chart/ActivitiesChart';


export const ActivityStatsProgress = ({ stats, hasFailedToLoad }) => {
  return (
    <div className="progress-chart-wrapper row mt-1">
        <LandingCard
          title="Activities (last 12 months)"
          link={''}
          linkText=""
          lastUpdatedTimestamp={null}
          query={{}}
          className='activities-insights-card full-height'
        >
          {hasStats(stats) ? 
            <ActivitiesChart
              data={stats}
              hasFailedToLoad={hasFailedToLoad}
            />
          :
            <div className="mt-4">Complete your first Activity to see how your progress will show</div>
          }
        </LandingCard>
    </div>
  );

};

export default ActivityStatsProgress;
