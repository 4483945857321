import React from 'react';
import moment from 'moment';
import { Link } from 'react-router';
import { List } from 'immutable';
import { connect } from 'react-redux';

import { isComplete, linkRelatedTemplates } from '^/models/activities';
import { clearItem } from '^/actions/items';
import { ACTIVITY_RELATED_TEMPLATE } from '^/consts/collectionKeys';
import {
  getBaseTemplateDocument,
  isCopiedOrAdoptedDocument,
  getDocumentLink,
  getDocumentListsFromActivity,
  templateDocumentKindIsPolicyOrOverview,
  DOCUMENT_TYPE
} from '^/models/documents';
import { isNotReadOnlyInCurrentPractice, isAdmin } from '^/models/user';
import { formatDate, isGroupDocument } from '^/utils';
import { openRemoveUploadedActivityFileModal, openTogglePrivacyUploadedActivityTaskFileModal } from '^/actions/modals';
import { DocumentEditingMayDisallow, isDocumentLocked } from '^/components/app/perms/DocumentEditingMayDisallow';
import PermissionsComponent from '^/components/app/perms/PermissionsComponent';
import Icon from '^/components/app/content/Icon';
import UpdateToIfLaterVersion from '^/components/app/template-documents/UpdateToIfLaterVersion';
import TemplateDocumentTypeIcon from '^/components/app/template-documents/TemplateDocumentTypeIcon';
import ActivityTaskAssigneeDocumentUpload from './ActivityTaskAssigneeDocumentUpload';
import DownloadDocumentButton from './DownloadDocumentButton';
import Notes from '^/components/app/widgets/Notes';
import TemplateDocumentLink from './TemplateDocumentLink';
import Collapse from '^/components/app/content/Collapse';
import CollapseButton from '^/components/app/content/CollapseButton';
import { isLoggedOnUserAdminOfCurrentPractice } from '^/stateHelpers';

export const TemplateDocument = ({
  name,
  versionNumber,
  userFullName,
  folder,
  activityId,
  taskId,
  downloadLink,
  templateVersion,
  noModify,
  code,
  showGroupDocumentEdit,
  userId,
  location,
  isAdminOfPractice
}) => {
  const { id, modified, is_copiable, copied_template_document } = templateVersion.toObject();
  const baseDocument = getBaseTemplateDocument(templateVersion);
  const isFresh = is_copiable && !copied_template_document;
  const parentDocument = (baseDocument && !templateDocumentKindIsPolicyOrOverview(baseDocument) && copied_template_document)
    ? copied_template_document.get('template_document_version') : id;
  const editDocumentLink = activityId ?
    linkRelatedTemplates(activityId, parentDocument) : (taskId && getDocumentLink(baseDocument.get('id')));

  const editGroupDocumentLink =
    `/page/documents/group/${baseDocument && baseDocument.get('id')}/`;

  const locked = isDocumentLocked(templateVersion, userId);

  return (
    <div>
      <div>
        <TemplateDocumentLink
          editDocumentLink={editDocumentLink}
          downloadLink={downloadLink}
          templateVersion={templateVersion}
          targetBlank={!!taskId}
          userId={userId}
          userIsAdmin={isAdminOfPractice}
        >
          <TemplateDocumentTypeIcon large templateDocument={baseDocument} />
          {code} {name} V{versionNumber}
        </TemplateDocumentLink>
        <DocumentEditingMayDisallow templateVersion={templateVersion} hideMessage className="pull-right" >
          {!locked && showGroupDocumentEdit &&
            <Link
              templateVersion={templateVersion}
              className="btn btn-default small mr-1-4"
              to={{
                pathname: editGroupDocumentLink,
                state: {backLink: location.pathname, backText: 'activity'}
              }}
              target={taskId && '_blank'}
            >
              Edit Group Version
            </Link>
          }
          {!locked && !downloadLink && editDocumentLink && !noModify && (
            <Link
              templateVersion={templateVersion}
              className="btn btn-default small indented"
              to={editDocumentLink}
              target={taskId && '_blank'}
            >
              <Icon type="edit" className="button-icon" /> {isFresh ? 'Enter details' : 'View'}
            </Link>
          )}
        </DocumentEditingMayDisallow>
      </div>
      <div className="hint">
        Updated by {userFullName} on {formatDate(modified)}.
        {folder && ` Stored in Folder ${folder.get('number')}.`}
      </div>
      <hr className="thin" />
    </div>
  );
};

export const GlobalOrGroupTemplateDocument = ({
  templateDocument, activityId, taskId, noModify, showGroupDocumentEdit, userId, location, isAdminOfPractice }) => {
  const {
    user,
    template_document,
    group_template_document,
    version_number,
    content_file,
  } = templateDocument.toObject();

  let folder, name, code;
  if (template_document) {
    folder = template_document.get('folder');
    name = template_document.get('name');
    code = template_document.get('code');
  } else {
    folder = group_template_document.get('document_folder');
    name = group_template_document.getIn(['template_document', 'name']);
    code = group_template_document.getIn(['template_document', 'code']);
  }

  const updatedUser = isAdmin(user) ? 'iComply Admin' : user.getIn(['staffdetail', 'full_name'])

  return (
    <TemplateDocument
      noModify={noModify}
      templateVersion={templateDocument}
      activityId={activityId}
      taskId={taskId}
      name={name}
      versionNumber={version_number}
      downloadLink={content_file}
      userFullName={updatedUser}
      folder={folder}
      code={code}
      showGroupDocumentEdit={showGroupDocumentEdit}
      userId={userId}
      location={location}
      isAdminOfPractice={isAdminOfPractice}
    />
  );
};

export const AdoptedOrCopiedTemplateDocument = ({
  relatedTemplate, activityId, taskId, noModify, showGroupDocumentEdit, userId, location, isAdminOfPractice }) => {
  const {
    user,
    adopted_template_document,
    copied_template_document,
  } = relatedTemplate.toObject();

  const {
    created,
    document_version_number,
    document_name,
    document_folder
  } = (adopted_template_document || copied_template_document).toObject();
  const code = (adopted_template_document || copied_template_document).getIn(['template_document', 'code']);


  const updatedUser = isAdmin(user) ? 'iComply Admin' : user.getIn(['staffdetail', 'full_name'])

  return (
    <div>
      <span className="small-hint">
        <span className="text-uppercase">Practice Document </span>
        ({adopted_template_document ? 'adopted' : 'copied'} {moment(created).format('DD/MM/YYYY')})
        {!noModify && (
          <UpdateToIfLaterVersion
            adoptedDocument={adopted_template_document}
            copiedDocument={copied_template_document}
            activityId={activityId}
          />
        )}
      </span>
      <TemplateDocument
        noModify={noModify}
        templateVersion={relatedTemplate}
        activityId={activityId}
        taskId={taskId}
        name={document_name}
        versionNumber={document_version_number}
        userFullName={updatedUser}
        folder={document_folder}
        code={code}
        showGroupDocumentEdit={showGroupDocumentEdit}
        userId={userId}
        location={location}
        isAdminOfPractice={isAdminOfPractice}
      />
    </div>
  );
};

export const UploadedActivityTaskFile = ({
  uploadedActivityTaskFile, removeDocument, togglePrivateDocument, userId, showNotes
}) => {
  const {
    user: { staffdetail: { full_name } },
    modified,
    id,
    file,
    link,
    name,
    folder,
    last_downloaded_by,
    last_downloaded_date,
    is_secure_document,
    description,
  } = uploadedActivityTaskFile.toJS();

  const owned = (userId === uploadedActivityTaskFile.getIn(['user', 'id']));

  return (
    <div>
      <div>
        <TemplateDocumentLink webLink={link} downloadLink={file} isUploadedFile>
          <Icon type="file-text-o" className="inline-icon" />{name}
        </TemplateDocumentLink>
        <div className="pull-right">
          {removeDocument && (
            <a
              className="btn btn-warning small indented"
              onClick={() => removeDocument(uploadedActivityTaskFile)}
            >
              <Icon type="close" className="button-icon" />Remove
            </a>
          )}
          {togglePrivateDocument && owned && (
            <PermissionsComponent hasPermission={isNotReadOnlyInCurrentPractice} >
              <a
                className="btn btn-default small indented ml-1-2"
                onClick={() => togglePrivateDocument(uploadedActivityTaskFile)}
              >
                <Icon type="lock" className="button-icon" />
                {is_secure_document ? 'Make Viewable' : 'Make Private'}
              </a>
            </PermissionsComponent>
          )}
          <DownloadDocumentButton
            className="small"
            documentId={id}
            documentType={DOCUMENT_TYPE.ATTACHED}
            webLink={link}
            trackedDownloadLink={file}
          />
        </div>
      </div>
      {
        last_downloaded_by && last_downloaded_by.staffdetail && last_downloaded_by.staffdetail.full_name ? (
          <div className="hint">
            Last downloaded by { last_downloaded_by.staffdetail.full_name } on { formatDate(last_downloaded_date) }.{
              folder && ` Stored in Folder ${folder.number}.`}
          </div>
        ) : (
          <div className="hint">
            Uploaded by {full_name} on {formatDate(modified)}.{folder && ` Stored in Folder ${folder.number}.`}
          </div>
        )
      }
      {showNotes && description && (
        <Notes key={id} emptyMessage="Please add some completion notes" value={`Description: ${description}`} />
      )}
      <hr className="thin" />
    </div>
  );
};

export const RelatedTemplate = ({relatedTemplate, activityId, taskId, noModify, showGroupDocumentEdit, userId, location, isAdminOfPractice}) =>
  (isCopiedOrAdoptedDocument(relatedTemplate)) ? (
    <AdoptedOrCopiedTemplateDocument
      relatedTemplate={relatedTemplate}
      activityId={activityId}
      taskId={taskId}
      noModify={noModify}
      showGroupDocumentEdit={showGroupDocumentEdit}
      userId={userId}
      location={location}
      isAdminOfPractice={isAdminOfPractice}
    />
  ) : (
    <GlobalOrGroupTemplateDocument
      templateDocument={relatedTemplate}
      activityId={activityId}
      taskId={taskId}
      noModify={noModify}
      showGroupDocumentEdit={showGroupDocumentEdit}
      userId={userId}
      location={location}
      isAdminOfPractice={isAdminOfPractice}
    />
  );

export const ActivityRelatedTemplates = ({
  activityId,
  completed,
  noModify,
  relatedTemplates,
  uploadedActivityTaskFiles,
  removeDocument,
  showGroupDocumentEdit,
  togglePrivateDocument,
  userId,
  location,
  isAdminOfPractice
}) => {
  const noFiles = relatedTemplates.isEmpty() && uploadedActivityTaskFiles.isEmpty();

  return (

    <div className="collapsible-section-activities">
    <CollapseButton collapseId={'TEMPLATES_COLLPASE_ID'}>
      <h2>Related Templates and Guidance:</h2>
    </CollapseButton>
    <Collapse overrideCollapsed={noFiles} collapseId={'TEMPLATES_COLLPASE_ID'}>
      <div className="mt-1">
        <div className="help-block mb-1">
          <Icon type="lightbulb-o" className="tip-icon" />
          You can use these to help you complete this compliance review.
        </div>

        {noFiles ? (
          <div>
            <p>There are no related templates for this activity</p>
          </div>
        ) : (
          <div>
            {relatedTemplates.map(template =>
              <RelatedTemplate
                key={template.get('id')}
                relatedTemplate={template}
                activityId={activityId}
                noModify={noModify}
                showGroupDocumentEdit={showGroupDocumentEdit}
                userId={userId}
                location={location}
                isAdminOfPractice={isAdminOfPractice}
              />
            )}
            {!completed && uploadedActivityTaskFiles.map(file =>
              <UploadedActivityTaskFile
                key={file.get('id')}
                uploadedActivityTaskFile={file}
                removeDocument={!noModify && removeDocument}
                togglePrivateDocument={!noModify && togglePrivateDocument}
                userId={userId}
              />
            )}
          </div>
        )}
      </div>
    </Collapse>
    <hr className="strong" />
    </div>
  );
};

export const AttachedDocument = ({attachedDocument}) => {
  const { document_name, document_version, export: { id, download_url, filename } } = attachedDocument.toJS();

  const name = attachedDocument
    .getIn(['template_document_version', 'copied_template_document', 'name']) ||
    attachedDocument.getIn(['template_document_version', 'adopted_template_document', 'name']) ||
    `${document_name} V${document_version}`;

  return (
    <div>
      <div>
        <a className="underlined" download href={download_url}>
          <Icon type="file-text-o" className="inline-icon" />{name}
        </a>

        <div className="pull-right">
          <DownloadDocumentButton
            className="small"
            downloadPDFLink={download_url}
            filename={filename}
            exportId={id}
          />
        </div>
      </div>
      <hr className="thin" />
    </div>
  );
};

const ATTACHED_DOCUMENTS_COLLPASE_ID = 'ATTACHED_DOCUMENTS_COLLPASE_ID';

export const ActivityAttachedDocuments = ({
  attachedDocuments, uploadedActivityTaskFiles
}) =>

    <div className="collapsible-section-activities">
      <CollapseButton collapseId={ATTACHED_DOCUMENTS_COLLPASE_ID}>
        <h2>Attached Practice Documents:</h2>
      </CollapseButton>
      <Collapse initiallyCollapsed collapseId={ATTACHED_DOCUMENTS_COLLPASE_ID}>
        <div className="help-block mb-1">
          <Icon type="lightbulb-o" className="tip-icon" />
          These are copies of the related templates generated when the activity was completed.
        </div>

        {
          attachedDocuments.isEmpty() && uploadedActivityTaskFiles.isEmpty() ?
            <div>
                <p>There are no attached documents for this activity</p>
                <hr className="thin" />
            </div>
            :
            <div>
              {uploadedActivityTaskFiles.map(file =>
                <UploadedActivityTaskFile
                  key={file.get('id')}
                  uploadedActivityTaskFile={file}
                  removeDocument={false}
                />
              )}
              {attachedDocuments.map(doc =>
                <AttachedDocument
                  attachedDocument={doc}
                  key={doc.get('id')}
                />
              )}
            </div>
        }
      </Collapse>
    </div>;

export const ActivityDocuments = ({activity, removeDocument, showGroupDocumentEdit, togglePrivateDocument, clearActivityTemplate, userId, location, isAdminOfPractice}) => {
  const uploadedActivityTaskFiles = (activity && activity.get('uploaded_activity_files')) || List();
  const completed = isComplete(activity);
  const { attachedDocuments, relatedTemplates } = getDocumentListsFromActivity(activity);

  clearActivityTemplate();

  return (
    <div>
      <ActivityRelatedTemplates
        activityId={activity && activity.get('id')}
        completed={completed}
        relatedTemplates={relatedTemplates}
        uploadedActivityTaskFiles={uploadedActivityTaskFiles}
        noModify={completed}
        removeDocument={
          (uploadedActivityTaskFile) => removeDocument(activity && activity.get('id'), uploadedActivityTaskFile)
        }
        showGroupDocumentEdit={showGroupDocumentEdit}
        togglePrivateDocument={
          (uploadedActivityTaskFile) =>
            togglePrivateDocument(activity && activity.get('id'), uploadedActivityTaskFile)
        }
        userId={userId}
        location={location}
        isAdminOfPractice={isAdminOfPractice}
      />

      { !completed && (<ActivityTaskAssigneeDocumentUpload activityId={activity && activity.get('id')} />)}

      {completed && (
        <ActivityAttachedDocuments
          attachedDocuments={attachedDocuments}
          uploadedActivityTaskFiles={uploadedActivityTaskFiles}
        />
      )}
    </div>
  );
};

export function mapStateToProps(state) {
  const { userProfile, currentPractice } = state;

  const showGroupDocumentEdit = isGroupDocument(currentPractice, userProfile);
  const userId = userProfile.get('id');
  const isAdminOfPractice = isLoggedOnUserAdminOfCurrentPractice(state)

  return {
    showGroupDocumentEdit,
    userId,
    location: state.routing.location,
    isAdminOfPractice
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    removeDocument: (activityId, uploadedActivityTaskFile) => dispatch(openRemoveUploadedActivityFileModal(activityId, uploadedActivityTaskFile)),
    togglePrivateDocument: (activityId, uploadedActivityTaskFile) => dispatch(openTogglePrivacyUploadedActivityTaskFileModal(activityId, uploadedActivityTaskFile)),
    clearActivityTemplate: () => dispatch(clearItem(ACTIVITY_RELATED_TEMPLATE))
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (ActivityDocuments);
