import { List } from 'immutable';
import { ALL_PRACTICE_MEMBERS } from '^/consts/collectionKeys';
import { STAFF_PRACTICE_TYPE, STATUS_CHOICES } from '^/models/staffPractice';
import { isPending } from '^/consts/responseStates';
import { isPracticesGroupAdmin } from '^/utils';

export function getPracticeMembers(state) {
  const practiceMembers = (state.currentPractice && state.currentPractice.get('members')) || List();

  return practiceMembers.map(each => each.getIn(['user', 'staffdetail'])).filter(x => x);
}

export function getActivePracticeUsers(state) {
  const isActive = practiceMember =>
    practiceMember.get('status') === STATUS_CHOICES.ACTIVE;
  const isNotInsightsOnly = practiceMember =>
    practiceMember.get('type') !== STAFF_PRACTICE_TYPE.INSIGHTS_ONLY;

  const practiceMembers =
    (state.currentPractice && state.currentPractice.get('members')) || List();
  return practiceMembers.filter(isActive).filter(isNotInsightsOnly);
}

export function getActivePracticeMembers(state) {
  return getActivePracticeUsers(state)
    .map(each => each.getIn(['user', 'staffdetail']))
    .filter(x => x);
}

export function getActiveCycleSetUpPracticeMembers(state) {
  return state.teamMembers && state.teamMembers.filter(teamMember => { return teamMember.get('status') === 'ACTIVE' })
}

export function isLoggedOnUserAdminOfCurrentPractice(state) {
  return state.userProfile.get('is_admin_of_current_practice', false);
}

export function getCurrentGroup(state) {
  return state.currentPractice && state.currentPractice.get('group');
}

export function isLoggedOnUserAdminOfCurrentGroup(state) {
  // NOTE: this cannot use the currentPractice part of state as it's not loaded at the point of initial permissions check
  const profile = state.userProfile;
  const currentPractice = profile && profile.getIn(['staffdetail', 'current_practice']);
  return Boolean(profile && currentPractice && isPracticesGroupAdmin(currentPractice, profile.get('id')));
}

export function isLoggedOnUserInsightsOnlyGroupUser(state) {
  return state.userProfile && state.userProfile.get('is_insights_only_groups_user');
}

export function isImpersonateUser(state) {
  const profile = state.userProfile;
  return profile && profile.get('is_impersonate')
}

export function isLoggedOnUserPracticeAdmin(state) {
  // If you want to use this function you must ensure that ALL_PRACTICE_MEMBERS
  // have been fetched from the backend for practice you wish to check.

  const userId = state.userProfile.get('id');

  const member =
    state
      .collections
      .getIn([ALL_PRACTICE_MEMBERS, 'items'])
      .find(each =>
        (each.getIn(['user', 'id']) === userId) &&
        (each.get('type') === STAFF_PRACTICE_TYPE.PRACTICE_ADMIN)
      );

  return !!member;
}

export function isUpdatingFormField(state, type, fieldName) {
  return isPending(state.responses.getIn(['updateItem', type]))
    && state.pendingFormFields.getIn([type, fieldName]);
}

export function isCurrentPracticeDemoCalendarOnly(state) {
  return state.currentPractice && state.currentPractice.get('is_demo_calendar_only', true);
}

export function hasAccessToGroupDashboard(state) {
  return state.currentPractice && state.currentPractice.getIn(['group', 'has_group_dashboard_access']);
}

export function practiceGroupHasAccessToGroupConfiguration(state) {
  return state.currentPractice && state.currentPractice.getIn(['group', 'has_group_configuration_access']);
}

export function hasAccessToAdoptedGroupDocuments(state) {
  return state.currentPractice && state.currentPractice.getIn(['group', 'is_using_group_documents']);
}

export function practiceHasAccessToDigitalAudits(state) {
  return state.currentPractice && state.currentPractice.get('has_active_audit_types');
}

export function practiceHasAccessToDigitalRecords(state) {
  return state.currentPractice && state.currentPractice.get('has_digital_records_access');
}

export function practiceHasAccessToDigitalRecordsQuickCheck(state) {
  return state.currentPractice && state.currentPractice.get('has_digital_records_quick_check_access');
}

export function practiceHasAccessToDigitalTools(state) {
  return practiceHasAccessToDigitalAudits(state) || practiceHasAccessToDigitalRecords(state);
}

export function practiceHasAccessToDocumentReviews(state) {
  return state.currentPractice && state.currentPractice.get('has_icomply_lite_document_reviews_access');
}

export function practiceHasDocumentsToReadAccess(state) {
  return state.currentPractice && state.currentPractice.get('has_documents_to_read_access');
}

export function practiceHasReportsTabAccess(state) {
  return state.currentPractice && state.currentPractice.get('cycle_configuration') !== 'LITE';
}
