/*
 * Unfortunately, these actions need to know about react components
 */
import { Map } from 'immutable';

import * as MODAL_IDS from '^/modals/ids';
import { getDocumentReviewTitle } from '^/utils';

export const OPEN_MODAL = 'OPEN_MODAL';
export function openModal(config) {
  return {
    type: OPEN_MODAL,
    payload: Map(config),
  };
}

export const CLOSE_MODAL = 'CLOSE_MODAL';
export function closeModal() {
  return {
    type: CLOSE_MODAL,
  };
}

export const REMOVE_CLOSE_BUTTON_WHILE_POLLING =
  'REMOVE_CLOSE_BUTTON_WHILE_POLLING';
export function removeCloseButtonWhilePolling() {
  return {
    type: REMOVE_CLOSE_BUTTON_WHILE_POLLING,
  };
}

export const ADD_CLOSE_BUTTON_AFTER_POLLING = 'ADD_CLOSE_BUTTON_AFTER_POLLING';
export function addCloseButtonAfterPolling() {
  return {
    type: ADD_CLOSE_BUTTON_AFTER_POLLING,
  };
}

export function openCreateTaskModal(props) {
  return openModal({
    title: 'Create A Task',
    id: MODAL_IDS.CREATE_TASK,
    props,
  });
}

export function openCreateEditGroupTaskModal(props) {
  return openModal({
    title: 'Create A Group Task',
    id: MODAL_IDS.CREATE_EDIT_GROUP_TASK_MODAL,
    props,
  });
}

export function openManageGroupTaskModal(props) {
  return openModal({
    title: 'Manage A Group Task',
    id: MODAL_IDS.CREATE_EDIT_GROUP_TASK_MODAL,
    props,
  });
}

export function openArchiveGroupTaskModal(props) {
  return openModal({
    title: 'Archive Group Task',
    id: MODAL_IDS.ARCHIVE_GROUP_TASK_MODAL,
    props,
  });
}

export function openEditPracticeModal(practice) {
  return openModal({
    title: 'Edit Practice',
    id: MODAL_IDS.EDIT_PRACTICE_MODAL,
    props: { practice },
  });
}

export function openEditGroupDetailsModal(group) {
  return openModal({
    title: 'Edit Group Details',
    id: MODAL_IDS.EDIT_GROUP_DETAILS_MODAL,
    props: { group },
  });
}

export function openTeamMemberModal(
  onSubmitNewMember,
  onSubmitExistingMembers,
  className
) {
  return openModal({
    emptyModal: true,
    id: MODAL_IDS.ADD_NEW_OR_EXISTING_TEAM_MEMBER_TABSET,
    props: {
      onSubmitNewMember,
      onSubmitExistingMembers,
      className,
    },
  });
}

export function openExportTableModal(model, download) {
  return openModal({
    title: 'Download ' + model,
    id: MODAL_IDS.EXPORT_MODAL_CONTENT,
    props: {
      model,
      ...download,
    },
  });
}

export function openEditMemberModal(member, practiceId) {
  return openModal({
    title: 'Edit User',
    id: MODAL_IDS.EDIT_MEMBER_MODAL_CONTENT,
    props: {
      member,
      practiceId,
    },
  });
}

export function openPracticeMemberActivitiesModal(member) {
  return openModal({
    title: "Edit User's Activites",
    id: MODAL_IDS.EDIT_MEMBER_ACTIVITIES_MODAL,
    props: { member },
    wide: true,
  });
}

export function openSuspendPracticeMemberModal(member, practiceId) {
  return openModal({
    title: 'Suspend User',
    id: MODAL_IDS.SUSPEND_MEMBER_MODAL,
    props: {
      member,
      practiceId,
    },
    wide: true,
  });
}

export function openRetirePracticeMemberModal(member, practiceId) {
  return openModal({
    title: 'Retire User',
    id: MODAL_IDS.RETIRE_MEMBER_MODAL,
    props: {
      member,
      practiceId,
    },
    wide: true,
  });
}

export function openReinstatePracticeMemberModal(member, practiceId) {
  return openModal({
    title: 'Reinstate User',
    id: MODAL_IDS.REINSTATE_MEMBER_MODAL,
    props: {
      member,
      practiceId,
    },
  });
}

export function openSetupCycleDateFormModal(practice) {
  return openModal({
    title: 'Choose Cycle Start Date',
    id: MODAL_IDS.SETUP_CYCLE_DATE_FORM_MODAL,
    props: { practice },
  });
}

export function openUploadActivityTaskAssigneeFileModal(
  activityOrTaskAssigneeId,
  save
) {
  return openModal({
    title: 'Link to/upload related documents',
    id: MODAL_IDS.UPLOAD_ACTIVITY_TASK_ASSIGNEE_FILE_MODAL,
    props: {
      activityOrTaskAssigneeId,
      save,
    },
  });
}

export function openRemoveUploadedActivityFileModal(activityId, uploadedFile) {
  return openModal({
    title: 'Remove related document',
    id: MODAL_IDS.REMOVE_UPLOADED_ACTIVITY_FILE_MODAL,
    props: {
      activityId,
      uploadedFile,
    },
  });
}

export function openTogglePrivacyUploadedActivityTaskFileModal(
  activityId,
  uploadedFile
) {
  return openModal({
    title: 'Change privacy of related document',
    id: MODAL_IDS.TOGGLE_PRIVACY_UPLOADED_ACTIVITY_TASK_FILE_MODAL,
    props: {
      activityId,
      uploadedFile,
    },
  });
}

export function openRemoveUploadedTaskAssigneeFileModal(
  taskAssigneeId,
  taskId,
  uploadedFile
) {
  return openModal({
    title: 'Remove related document',
    id: MODAL_IDS.REMOVE_UPLOADED_ACTIVITY_FILE_MODAL,
    props: {
      taskAssigneeId,
      taskId,
      uploadedFile,
    },
  });
}

export function openTogglePrivateUploadedTaskFileModal(
  taskAssigneeId,
  taskId,
  uploadedFile
) {
  return openModal({
    title: 'Change privacy of related document',
    id: MODAL_IDS.TOGGLE_PRIVACY_UPLOADED_ACTIVITY_TASK_FILE_MODAL,
    props: {
      taskAssigneeId,
      taskId,
      uploadedFile,
    },
  });
}

export function openChangeUploadedTaskFileFolderModal(
  activityTaskId,
  fileId,
  currentFolderId
) {
  return openModal({
    title: 'Change folder',
    id: MODAL_IDS.CHANGE_UPLOADED_TASK_FILE_FOLDER_MODAL,
    props: {
      activityTaskId,
      fileId,
      currentFolderId,
    },
  });
}

export function openConfirmDemoResetModal() {
  return openModal({
    title: 'Confirm Reset',
    id: MODAL_IDS.CONFIRM_DEMO_RESET_MODAL,
  });
}

export function openConfirmUpgradeDemoModal() {
  return openModal({
    title: 'Confirm Upgrade',
    id: MODAL_IDS.CONFIRM_UPGRADE_DEMO_MODAL,
  });
}

export function openDiscardWorkingVersionModal(
  workingVersionId,
  templateVersion
) {
  return openModal({
    title: 'Discard Working Version',
    id: MODAL_IDS.DISCARD_WORKING_VERSION_MODAL,
    props: {
      workingVersionId,
      templateVersion,
    },
  });
}

export function openPublishAsCurrentVersionModal(templateVersion, data) {
  return openModal({
    title: 'Publish as Current Version',
    id: MODAL_IDS.PUBLISH_AS_CURRENT_VERSION_MODAL,
    props: {
      data,
      templateVersion,
    },
  });
}

export function openConfirmChangeActivityDateModal(date, activity) {
  return openModal({
    title: 'Confirm change activity date',
    id: MODAL_IDS.CONFIRM_CHANGE_ACTIVITY_DATE_MODAL,
    props: {
      date,
      activity,
    },
  });
}

export function openPublishAsNewVersionModal(
  templateVersion,
  templateDocument,
  data
) {
  return openModal({
    title: 'Publish as New Version',
    id: MODAL_IDS.PUBLISH_AS_NEW_VERSION_MODAL,
    props: {
      data,
      templateDocument,
      templateVersion,
    },
  });
}

export function openPublishDraftModal(data, onComplete) {
  return openModal({
    title: 'Publish Draft',
    id: MODAL_IDS.PUBLISH_DRAFT_MODAL,
    props: {
      data,
      onComplete,
    },
  });
}

export function openPracticeReportModal(download) {
  return openModal({
    title: 'Practice Report',
    id: MODAL_IDS.REPORT_MODAL_CONTENT,
    props: download,
  });
}

export function openMarkTaskCompleteModal(taskAssignee, task) {
  return openModal({
    title: 'Mark Task Complete',
    id: MODAL_IDS.MARK_TASK_COMPLETE_MODAL,
    props: {
      taskAssignee,
      task,
    },
  });
}

export function openApplyTemplateActivityToLiveCyclesModal(
  templateActivity,
  monthOverride
) {
  return openModal({
    title: `Apply ${templateActivity.get('name')} to live cycles`,
    id: MODAL_IDS.APPLY_TEMPLATE_ACTIVITY_TO_LIVE_CYCLES_MODAL,
    props: { templateActivity, monthOverride },
    closable: false,
  });
}

export function openReportTableModal(reportType, download) {
  return openModal({
    title: 'Generate ' + reportType.get('display_name'),
    id: MODAL_IDS.REPORT_MODAL_CONTENT,
    props: {
      reportType,
      ...download,
    },
  });
}

export function openPracticeAdoptionModal(template) {
  return openModal({
    title: 'Practice document adoption',
    id: MODAL_IDS.PRACTICE_ADOPTION_MODAL,
    props: { template },
  });
}

export function openCycleReportModal(cycle, download) {
  return openModal({
    title: 'Download Cycle Report',
    id: MODAL_IDS.REPORT_MODAL_CONTENT,
    props: download,
  });
}

export function openHelpModal(topic) {
  return openModal({
    title: `Help - ${topic.get('title')}`,
    id: MODAL_IDS.HELP_MODAL,
    props: { topic },
  });
}

export function openReassignPrivateDocumentsModal(templates) {
  return openModal({
    title: 'Reassign private documents',
    id: MODAL_IDS.REASSIGN_PRIVATE_DOCUMENTS_MODAL,
    props: { templates },
  });
}

export function openRestoreAdoptedVersionModal(
  docId,
  activityId,
  redirectToFunc
) {
  return openModal({
    title: 'Restore Document',
    id: MODAL_IDS.RESTORE_ADOPTED_VERSION_MODAL,
    props: {
      docId,
      activityId,
      redirectToFunc,
    },
  });
}

export function openExitConfirmationModal(
  document,
  content,
  groupId,
  paramsId,
  onSave,
  discard
) {
  return openModal({
    emptyModal: true,
    closable: false,
    id: MODAL_IDS.EXIT_CONFIRMATION_MODAL,
    props: {
      document,
      content,
      groupId,
      paramsId,
      onSave,
      discard,
    },
  });
}

export function openComparisonEditorExitConfirmationModal(
  document,
  content,
  groupId,
  paramsId,
  onSaveAndExit,
  onSaveAndPublish,
  discard
) {
  return openModal({
    emptyModal: true,
    closable: false,
    id: MODAL_IDS.COMPARISON_EDITOR_EXIT_CONFIRMATION_MODAL,
    props: {
      document,
      content,
      groupId,
      paramsId,
      onSaveAndExit,
      onSaveAndPublish,
      discard,
    },
  });
}

export function openNameDocumentCopyModal(
  doc,
  content,
  currentPractice,
  defaultName,
  redirectToFunc,
  activityId,
  documentType
) {
  return openModal({
    title: 'Name Document Copy',
    id: MODAL_IDS.NAME_DOCUMENT_COPY_MODAL,
    props: {
      doc,
      content,
      currentPractice,
      redirectToFunc,
      defaultName,
      activityId,
      documentType,
    },
  });
}

export function openDeleteFolderConfirmationModal(
  folderId,
  folderName,
  hasGroupDocuments
) {
  return openModal({
    title: `Delete Folder '${folderName}'`,
    id: MODAL_IDS.DELETE_FOLDER_CONFIRMATION_MODAL,
    props: { folderId, folderName, hasGroupDocuments },
  });
}

export function openDocumentFolderExportModal(folder) {
  return openModal({
    title: `Download Folder '${folder.get('name')}'`,
    id: MODAL_IDS.DOCUMENT_FOLDER_EXPORT_MODAL,
    props: { folder },
  });
}

export function openDeleteCopyModal(id, originalId, activityId, name) {
  return openModal({
    title: 'Delete Copy',
    id: MODAL_IDS.DELETE_COPY_MODAL,
    props: {
      id,
      name,
      originalId,
      activityId,
    },
  });
}

export function openMaintenanceModal() {
  return openModal({
    title: 'Server error detected',
    id: MODAL_IDS.MAINTENANCE_MODAL,
  });
}

export function openReassignmentConfirmationModal(
  onReassign,
  onClose,
  loading
) {
  return openModal({
    title: 'Private Document Reassignment',
    id: MODAL_IDS.REASSIGN_CONFIRMATION_MODAL,
    props: {
      onReassign,
      onClose,
      loading,
    },
  });
}

export function openDemoModeDisallowsModal(props) {
  return openModal({
    title: 'Action disallowed in demo mode',
    props,
    closable: false,
  });
}

export function openDeleteAuditHealthcheckModal(props, modalId) {
  return openModal({
    id: modalId,
    props,
    title: 'Confirm Delete',
  });
}

export function openCompleteHealthcheckConfirmModal() {
  return openModal({
    id: MODAL_IDS.COMPLETE_HEALTHCHECK_CONFIRM_MODAL,
    emptyModal: true,
  });
}

export function openCreateGroupFolderModal() {
  return openModal({
    id: MODAL_IDS.CREATE_GROUP_FOLDER_MODAL,
    emptyModal: true,
  });
}

export function openPublishGroupDocumentModal(data, draftId) {
  return openModal({
    title: 'Publish as New Version',
    id: MODAL_IDS.PUBLISH_GROUP_DOCUMENT_MODAL,
    props: {
      data,
      draftId,
    },
  });
}

export function openGroupTemplateDocumentEditModal(templateDocument) {
  return openModal({
    title: 'Edit template document',
    id: MODAL_IDS.EDIT_GROUP_CREATED_DOCUMENT_MODAL,
    props: {
      templateDocument,
    },
  });
}

export function openGroupCreatedTemplateDocumentNewVersionModal(
  documentName,
  publishNewVersion
) {
  return openModal({
    title: 'Publish as New Version',
    id: MODAL_IDS.PUBLISH_GROUP_DOCUMENT_NEW_VERSION_MODAL,
    props: {
      documentName,
      publishNewVersion,
    },
  });
}

export function openDiscardCurrentAndAdoptNewVersionModal(
  documentContent,
  documentId,
  documentDetailLink,
  templateDocumentVersion,
  currentPracticeId,
  currentGroupId
) {
  return openModal({
    title: 'Discard Current And Adopt New Version',
    id: MODAL_IDS.DISCARD_CURRENT_AND_ADOPT_NEW_VERSION,
    props: {
      documentContent,
      documentId,
      documentDetailLink,
      templateDocumentVersion,
      currentPracticeId,
      currentGroupId,
    },
  });
}

export function openRemoveUploadedDocumentsModal(name, activityTaskId, fileId) {
  return openModal({
    title: 'Remove uploaded document',
    id: MODAL_IDS.REMOVE_UPLOADED_DOCUMENT,
    props: {
      name,
      activityTaskId,
      fileId,
    },
  });
}

export function openDocReadAddTeamMembersModal(
  templateDocumentVersionId,
  practiceId,
  documentVersionToReadId = null,
  alreadyAddedMembers = false
) {
  return openModal({
    title: 'Send document to team members',
    id: MODAL_IDS.DOC_READ_ADD_TEAM_MEMBERS_MODAL,
    props: {
      templateDocumentVersionId,
      practiceId,
      documentVersionToReadId,
      alreadyAddedMembers,
    },
  });
}

export function openDocumentToReadModal(documentReadRequestId) {
  return openModal({
    title: 'Read this document carefully',
    id: MODAL_IDS.DOCUMENT_TO_READ,
    props: {
      documentReadRequestId,
    },
    extraWide: true,
  });
}

export function openArchiveDocumentToReadModal(
  documentVersionToReadId,
  documentName
) {
  return openModal({
    title: 'Archive document read request',
    id: MODAL_IDS.ARCHIVE_DOCUMENT_TO_READ,
    props: {
      documentVersionToReadId,
      documentName,
    },
  });
}

export function openExitRecordFormConfirmationModal(
  discard,
  deleteDigitalRecordTasks
) {
  return openModal({
    emptyModal: true,
    closable: false,
    id: MODAL_IDS.EXIT_RECORD_FORM_CONFIRMATION_MODAL,
    props: {
      discard,
      deleteDigitalRecordTasks,
    },
  });
}

export function openDocumentTakeOverModal(
  templateVersion,
  userCurrentlyEditing,
  to
) {
  return openModal({
    title: 'Take over document',
    id: MODAL_IDS.DOCUMENT_TAKE_OVER_MODAL,
    props: {
      templateVersion,
      userCurrentlyEditing,
      to,
    },
  });
}

export function openCreateBulkDocReadModal(practiceId) {
  return openModal({
    props: {
      practiceId,
    },
    title: 'Create Bulk Doc Read',
    id: MODAL_IDS.CREATE_BULK_DOC_READ_MODAL,
  });
}

export function openCreateChecklistRecordGroupModal(
  name,
  checklistType,
  recordGroupMetadataFieldKeys
) {
  return openModal({
    props: {
      checklistType,
      recordGroupMetadataFieldKeys,
    },
    title: `Create a ${name}`,
    id: MODAL_IDS.CREATE_CHECKLIST_RECORD_GROUP_MODAL,
  });
}

export function openRefreshChecklistModal(recordGroupId, date) {
  return openModal({
    props: {
      recordGroupId,
      date,
    },
    title: `Refresh Checklist Confirmation`,
    id: MODAL_IDS.REFRESH_CHECKLIST_CONFIRMATION_MODAL,
  });
}

export function openArchiveChecklistModal(recordGroupId) {
  return openModal({
    props: {
      recordGroupId,
    },
    title: `Archive Checklist Confirmation`,
    id: MODAL_IDS.ARCHIVE_CHECKLIST_CONFIRMATION_MODAL,
  });
}

export function openCreateCustomFieldModal(headings, customChecklistFields) {
  return openModal({
    props: {
      headings,
      customChecklistFields,
    },
    title: `Create custom field`,
    id: MODAL_IDS.CREATE_CUSTOM_FIELD_MODAL,
  });
}

export function openExitCreateRecordWithCustomFieldsConfirmationModal(
  discard,
  removeCustomFields
) {
  return openModal({
    emptyModal: true,
    closable: false,
    id: MODAL_IDS.EXIT_CREATE_RECORD_WITH_CUSTOM_FIELDS_CONFIRMATION_MODAL,
    props: {
      discard,
      removeCustomFields,
    },
  });
}

export function openDocumentReviewModal(adoptedDocument) {
  return openModal({
    id: MODAL_IDS.DOCUMENT_REVIEW_CONFIRMATION_MODAL,
    title: getDocumentReviewTitle(adoptedDocument.get('document_review')),
    props: {
      adoptedDocument,
    },
  });
}

export function openInheritGroupsFunctionalityModal(
  practiceId,
  practiceGroupId,
  updateItemParams,
  updateItemResponseKey
) {
  return openModal({
    title: 'Inherit Groups pre-set functionality confirmation',
    id: MODAL_IDS.INHERIT_GROUPS_FUNCTIONALITY_MODAL,
    props: {
      practiceId,
      practiceGroupId,
      updateItemParams,
      updateItemResponseKey,
    },
  });
}
